import React, { useState } from 'react';

import { css } from '@emotion/css';
import { omit as _omit } from 'lodash';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import {
  AsyncMultiSelect,
  Button,
  Checkbox,
  InlineField,
  InlineSwitch,
  Input,
  PanelContainer,
  Select,
  useStyles2,
} from '@grafana/ui';

import { getWithHeaders } from '@/api';
import { useCurrentPage, usePageFilters } from '@/hooks';
import { paths } from '@/util/constants';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    buttonGroup: css({
      textAlign: 'right',
    }),
    cancelButton: css({
      marginRight: theme.spacing(1),
    }),
    dropdownPanel: css({
      backgroundColor: theme.colors.background.primary,
      left: 0,
      padding: theme.spacing(1),
      position: 'absolute',
      top: '100%',
      width: '100%',
      zIndex: theme.zIndex.modal,
    }),
    inline: css({
      alignItems: 'center',
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap',
    }),
    inlineField: css({
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    }),
    spanText: css({
      color: theme.colors.text.secondary,
    }),
  };
};

interface Props {
  setIsOpen: (isOpen: boolean) => void;
}

export const AdvancedFilter = ({ setIsOpen }: Props) => {
  const page = useCurrentPage();
  const styles = useStyles2(getStyles);
  const { showOnlySelected, toggleShowOnlySelected } = usePageFilters(page);
  const [checkboxStatus, setCheckboxStatus] = useState({
    alerts: false,
    dashboard: false,
    queries: false,
    seriesSaved: false,
  });
  const [filters, setFilters] = useState<{
    alerts?: string;
    dashboard?: string;
    labels?: SelectableValue<string>;
    queries?: string;
    seriesSaved?: string;
    type?: string;
  }>({});

  const loadAsyncData = async (path: string, query: string) => {
    const data = await getWithHeaders<{ data: string[] }>(path);

    return data.items[0].data
      .map((each) => ({ label: each, value: each }))
      .filter((each) => each.label.includes(query));
  };

  return (
    <PanelContainer data-testid="advanced-filter-panel" className={styles.dropdownPanel}>
      <InlineField className={styles.inlineField} label="Labels" grow transparent labelWidth={20}>
        <AsyncMultiSelect
          isSearchable
          loadOptions={(query) => loadAsyncData(paths.grafanaPromLabels, query)}
          defaultOptions
          onChange={(value) => {
            setFilters({ ...filters, labels: value });
          }}
        />
      </InlineField>
      <InlineField className={styles.inlineField} label="Dashboards" transparent labelWidth={20}>
        <div className={styles.inline}>
          <Checkbox
            onClick={(value) => {
              if (!value.currentTarget.checked) {
                setFilters({ ..._omit(filters, ['dashboard']) });
              }
              setCheckboxStatus({ ...checkboxStatus, dashboard: value.currentTarget.checked });
            }}
          />
          <Input
            type="number"
            disabled={!checkboxStatus.dashboard}
            value={filters.dashboard}
            onChange={(value) => {
              setFilters({ ...filters, dashboard: value.currentTarget.value });
            }}
          />
          <span className={styles.spanText}>Or fewer</span>
        </div>
      </InlineField>
      <InlineField className={styles.inlineField} label="Queries" transparent labelWidth={20}>
        <div className={styles.inline}>
          <Checkbox
            onClick={(value) => {
              if (!value.currentTarget.checked) {
                setFilters({ ..._omit(filters, ['queries']) });
              }
              setCheckboxStatus({ ...checkboxStatus, queries: value.currentTarget.checked });
            }}
          />
          <Input
            type="number"
            disabled={!checkboxStatus.queries}
            value={filters.queries}
            onChange={(value) => setFilters({ ...filters, queries: value.currentTarget.value })}
          />
          <span className={styles.spanText}>Or fewer</span>
        </div>
      </InlineField>
      <InlineField className={styles.inlineField} label="Alerts" transparent labelWidth={20}>
        <div className={styles.inline}>
          <Checkbox
            onClick={(value) => {
              if (!value.currentTarget.checked) {
                setFilters({ ..._omit(filters, ['alerts']) });
              }
              setCheckboxStatus({ ...checkboxStatus, alerts: value.currentTarget.checked });
            }}
          />
          <Input
            type="number"
            disabled={!checkboxStatus.alerts}
            value={filters.alerts}
            onChange={(value) => setFilters({ ...filters, alerts: value.currentTarget.value })}
          />
          <span className={styles.spanText}>Or fewer</span>
        </div>
      </InlineField>
      <InlineField className={styles.inlineField} label="Series saved" transparent labelWidth={20}>
        <div className={styles.inline}>
          <Checkbox
            onClick={(value) => {
              if (!value.currentTarget.checked) {
                setFilters({ ..._omit(filters, ['seriesSaved']) });
              }
              setCheckboxStatus({ ...checkboxStatus, seriesSaved: value.currentTarget.checked });
            }}
          />
          <Input
            type="number"
            disabled={!checkboxStatus.seriesSaved}
            value={filters.seriesSaved}
            onChange={(value) => setFilters({ ...filters, seriesSaved: value.currentTarget.value })}
          />
          <span className={styles.spanText}>Or fewer</span>
        </div>
      </InlineField>
      <InlineField className={styles.inlineField} grow label="Type" transparent labelWidth={20}>
        <Select onChange={() => {}} />
      </InlineField>
      <InlineSwitch
        label={`Show selected ${page}`}
        showLabel={true}
        transparent={true}
        value={showOnlySelected}
        onChange={() => toggleShowOnlySelected()}
      />
      <div className={styles.buttonGroup}>
        <Button className={styles.cancelButton} variant="secondary" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button>Apply</Button>
      </div>
    </PanelContainer>
  );
};
