import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Exemption } from '@/api/types';
import { formatDateTime } from '@/util/time';

interface Props {
  exemption: Exemption;
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    ddList: css({
      dd: {
        color: theme.colors.text.secondary,
        margin: 0,
      },
      display: 'grid',
      gridColumnGap: theme.spacing(2),
      gridTemplateColumns: '1fr 2fr',
      width: 'fit-content',
    }),
  };
};

export const ExemptionDetails = ({ exemption: { created_at, managed_by, updated_at } }: Props) => {
  const styles = useStyles2(getStyles);

  const createdAt = formatDateTime(created_at);
  const updatedAt = formatDateTime(updated_at);

  return (
    <div>
      <dl className={styles.ddList}>
        <dt>Created at</dt>
        <dd>{createdAt}</dd>
        <dt>Updated at</dt>
        <dd>{updatedAt}</dd>
        {managed_by && (
          <>
            <dt>Managed by</dt>
            <dd>{managed_by}</dd>
          </>
        )}
      </dl>
    </div>
  );
};
