import React, { useEffect, useState } from 'react';

import { SelectableValue } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { ConfirmModal } from '@grafana/ui';

import { ConfirmModalLoader } from '../ConfirmModalLoader';
import { handleLabels, handleMetric, handleReason, validateExemptionForm } from './utils';
import { Exemption } from '@/api/types';
import { ExemptionBuilderBody } from '@/components/ExemptionBuilderBody';
import { useCurrentPage } from '@/hooks';
import { errorAlert } from '@/util/alert';

interface Props {
  exemption?: Exemption;
  isLoading?: boolean;
  isOpen: boolean;
  onConfirm: (exemption: Partial<Exemption>) => Promise<unknown>;
  onDismiss: () => void;
}

export const ExemptionBuilderModal = ({ exemption, isLoading, isOpen, onConfirm, onDismiss }: Props) => {
  const [selectedMetric, setSelectedMetric] = useState<SelectableValue<string>>();
  const [selectedLabels, setSelectedLabels] = useState<Array<SelectableValue<string>>>([]);
  const [selectAllMetric, setSelectAllMetric] = useState(false);
  const [selectAllLabels, setSelectAllLabels] = useState(false);
  const [exemptionReason, setExemptionReason] = useState('');
  const [disableRecommendations, setDisableRecommendations] = useState<boolean>();
  const page = useCurrentPage();

  useEffect(() => {
    if (exemption && isOpen) {
      const { disable_recommendations, keep_labels, metric, reason } = exemption;
      setDisableRecommendations(disable_recommendations);

      handleMetric(setSelectedMetric, setSelectAllMetric, metric);
      handleLabels(setSelectedLabels, setSelectAllLabels, disable_recommendations, keep_labels);
      handleReason(setExemptionReason, reason);
    }
  }, [exemption, isOpen]);

  const resetForm = () => {
    setSelectedMetric(undefined);
    setSelectedLabels([]);
    setSelectAllMetric(false);
    setSelectAllLabels(false);
    setExemptionReason('');
    setDisableRecommendations(false);
  };

  const onModalConfirm = async () => {
    const payload: Partial<Exemption> = { id: exemption?.id };
    const errorMessage = validateExemptionForm(
      selectAllMetric,
      selectAllLabels,
      selectedLabels,
      selectedMetric,
      disableRecommendations
    );

    if (errorMessage) {
      if (!exemption) {
        errorAlert(errorMessage.trim(), 'Creation failed');
      } else {
        errorAlert(errorMessage.trim(), 'Update failed');
      }
    } else {
      if (selectedMetric?.value) {
        payload.metric = selectedMetric.value;
      }
      if (selectedLabels.length) {
        payload.keep_labels = selectedLabels.map((label) => label.value || '').filter((label) => label);
      }
      if (exemptionReason) {
        payload.reason = exemptionReason;
      }

      payload.disable_recommendations = disableRecommendations;

      reportInteraction('g_adaptive_metrics_app_exemption', {
        action: exemption ? 'update' : 'create',
        disableRecommendations: disableRecommendations,
        isAllLabel: selectAllLabels,
        isAllMetric: selectAllMetric,
        labelCount: selectedLabels.length,
        metric: Boolean(payload.metric),
        page,
      });

      await onConfirm(payload);
      resetForm();
      onDismiss();
    }
  };

  const onModalDismiss = () => {
    resetForm();
    onDismiss();
  };

  const getConfirmButtonText = (isLoading?: boolean, exemption?: Exemption) => {
    if (exemption) {
      return isLoading ? ((<ConfirmModalLoader text="Updating..." />) as unknown as string) : 'Update';
    }
    return isLoading ? ((<ConfirmModalLoader text="Creating..." />) as unknown as string) : 'Create';
  };

  return (
    <ConfirmModal
      title="Exemption builder"
      isOpen={isOpen}
      onConfirm={onModalConfirm}
      body={
        <ExemptionBuilderBody
          exemptionReason={exemptionReason}
          selectedMetric={selectedMetric}
          selectedLabels={selectedLabels}
          selectAllMetric={selectAllMetric}
          selectAllLabels={selectAllLabels}
          disableRecommendations={disableRecommendations}
          setDisableRecommendations={setDisableRecommendations}
          setExemptionReason={setExemptionReason}
          setSelectedMetric={setSelectedMetric}
          setSelectedLabels={setSelectedLabels}
          setSelectAllMetric={setSelectAllMetric}
          setSelectAllLabels={setSelectAllLabels}
        />
      }
      confirmButtonVariant="primary"
      confirmText={getConfirmButtonText(isLoading, exemption)}
      onDismiss={onModalDismiss}
    />
  );
};
