import React, { useMemo } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Column, Icon, useStyles2 } from '@grafana/ui';

import { SegmentsHeader } from '@/components/Configuration/Segments/header';
import { ContentBox } from '@/components/ContentBox';
import { TableWithCheckbox } from '@/components/TableWithCheckbox';
import { useSegments } from '@/hooks';
import { Segment } from '@/types';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    tableContainer: css({
      background: theme.colors.background.primary,
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    }),
  };
};

const getColumns = (): Array<Column<Segment>> => {
  return [
    {
      header: 'Name',
      id: 'name',
    },
    {
      header: 'Selector',
      id: 'selector',
    },
    {
      cell: ({ row: { original } }) => {
        return original.fallback_to_default ? (
          <div className={css({ textAlign: 'center' })}>
            <Icon name={'check'} />
          </div>
        ) : null;
      },
      disableGrow: true,
      header: (<div className={css({ textAlign: 'center' })}>Fallback to Default</div>) as unknown as string,
      id: 'fallback_to_default',
    },
    {
      cell: (_) => {
        return <div>actions</div>;
      },
      disableGrow: true,
      header: 'Actions',
      id: 'actions',
    },
  ];
};

export const Segments = () => {
  const styles = useStyles2(getStyles);
  const columns = useMemo(() => getColumns(), []);
  const { data: segmentsData } = useSegments();
  return (
    <ContentBox>
      <SegmentsHeader />
      <div className={styles.tableContainer}>
        <TableWithCheckbox
          columns={columns}
          data={segmentsData?.items || []}
          noRowsMessage={'No segments available'}
          pageSize={5}
        />
      </div>
    </ContentBox>
  );
};
