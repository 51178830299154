import React from 'react';

import { css } from '@emotion/css';
import { reject as _reject } from 'lodash';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { AsyncMultiSelect, AsyncSelect, Checkbox, Field, InlineSwitch, Input, Label, useStyles2 } from '@grafana/ui';

import { getWithHeaders } from '@/api';
import { paths } from '@/util/constants';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    checkbox: css({
      marginTop: `${theme.spacing(2.5)}`,
    }),
    container: css({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      overflow: 'hidden',
    }),
    dropdownLabelContainer: css({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    }),
    reasonLabel: css({
      div: {
        label: {
          paddingBottom: theme.spacing(1),
        },
      },
      paddingTop: theme.spacing(1),
    }),
  };
};

interface Props {
  disableRecommendations?: boolean;
  exemptionReason: string;
  selectAllLabels: boolean;
  selectAllMetric: boolean;
  selectedLabels?: Array<SelectableValue<string>>;
  selectedMetric?: SelectableValue<string>;
  setDisableRecommendations: (diableRecommendations?: boolean) => void;
  setExemptionReason: (reason: string) => void;
  setSelectAllLabels: (selectAll: boolean) => void;
  setSelectAllMetric: (selectAll: boolean) => void;
  setSelectedLabels: (labels: Array<SelectableValue<string>>) => void;
  setSelectedMetric: (metric?: SelectableValue<string>) => void;
}

export const ExemptionBuilderBody = ({
  disableRecommendations,
  exemptionReason,
  selectAllLabels,
  selectAllMetric,
  selectedLabels,
  selectedMetric,
  setDisableRecommendations,
  setExemptionReason,
  setSelectAllLabels,
  setSelectAllMetric,
  setSelectedLabels,
  setSelectedMetric,
}: Props) => {
  const styles = useStyles2(getStyles);

  const loadAsyncData = async (path: string, query: string) => {
    const data = await getWithHeaders<{ data: string[] }>(path);

    return data.items[0].data
      .map((each) => ({ label: each, value: each }))
      .filter((each) => each.label.includes(query));
  };

  return (
    <div className={styles.container}>
      <div className={styles.dropdownLabelContainer}>
        <Label htmlFor="metric-dropdown">Metric</Label>

        <InlineSwitch
          label="All"
          aria-label="metric-switch"
          showLabel
          transparent
          value={selectAllMetric}
          onChange={({ currentTarget }) => {
            setSelectAllMetric(currentTarget.checked);

            if (currentTarget.checked) {
              setSelectedMetric({ label: 'All metrics', value: undefined });
            } else {
              setSelectedMetric(undefined);
            }
          }}
        />
      </div>
      <AsyncSelect
        inputId="metric-dropdown"
        aria-label="metric-dropdown"
        isClearable
        isSearchable
        defaultOptions
        disabled={selectAllMetric}
        loadOptions={(query) => loadAsyncData(paths.grafanaPromMetrics, query)}
        value={selectedMetric}
        onChange={(value) => {
          setSelectedMetric(value);
        }}
        allowCustomValue
      />

      <div className={styles.checkbox}>
        <Checkbox
          aria-label="disable-recommendations"
          disabled={selectAllMetric}
          label="Disable recommendations for this metric and preserve existing rule state"
          value={disableRecommendations}
          onChange={(value) => {
            setDisableRecommendations(value.currentTarget.checked);

            if (value.currentTarget.checked) {
              setSelectedLabels([]);
              setSelectAllLabels(false);
            }
          }}
        />
      </div>

      <div className={styles.dropdownLabelContainer}>
        <Label htmlFor="label-dropdown">Keep labels</Label>

        <InlineSwitch
          disabled={disableRecommendations}
          label="All"
          aria-label="label-switch"
          showLabel
          transparent
          value={selectAllLabels}
          onChange={({ currentTarget }) => {
            setSelectAllLabels(currentTarget.checked);
            setSelectedLabels([]);
          }}
        />
      </div>
      <AsyncMultiSelect
        inputId="label-dropdown"
        aria-label="label-dropdown"
        defaultOptions
        disabled={disableRecommendations || selectAllLabels}
        isSearchable
        loadOptions={(query) => loadAsyncData(paths.grafanaPromLabels, query)}
        value={selectedLabels}
        placeholder={selectAllLabels ? 'All labels' : 'Choose'}
        onChange={(value) => {
          setSelectedLabels(value);
        }}
        allowCustomValue
      />

      <Field className={styles.reasonLabel} label="Reason">
        <Input
          value={exemptionReason}
          onChange={(value) => setExemptionReason(value.currentTarget.value)}
          placeholder="Optional"
        />
      </Field>
    </div>
  );
};
