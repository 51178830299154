import React, { useState } from 'react';

import { css } from '@emotion/css';
import { capitalize as _capitalize } from 'lodash';

import { GrafanaTheme2, IconName } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { Button, ConfirmModal, useStyles2 } from '@grafana/ui';

import { AggregationRecommendation, AggregationRule, Recommendation } from '@/api/types';
import {
  useCheckRulesMutation,
  useCurrentPage,
  useRules,
  useSelectedItems,
  useUpdateRulesMutation,
  useUserPermissions,
} from '@/hooks';
import { RuleRow } from '@/types';
import { warningAlert } from '@/util/alert';
import { URGENT_RECOMMENDATION } from '@/util/constants';
import {
  addOrUpdateRule,
  getRuleKey,
  removeRule,
  ruleDiff,
  ruleRowToRecommendationJson,
  ruleRowToRuleJson,
} from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => ({
  actionsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  }),
  tooltipStyling: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
  urgentButton: css({
    '&:hover': {
      backgroundColor: theme.colors.warning.transparent,
      border: `1px solid ${theme.colors.emphasize(theme.colors.warning.border, 0.25)}`,
    },
    backgroundColor: theme.colors.warning.transparent,
    border: `1px solid ${theme.colors.warning.border}`,
    color: theme.colors.getContrastText(theme.colors.warning.transparent),
  }),
});

const actionIcons: { [k in Recommendation]: IconName } = {
  add: 'plus',
  keep: 'check',
  remove: 'trash-alt',
  unknown: 'question-circle',
  update: 'arrow-up',
};

type Props = {
  ruleRow: RuleRow;
};
export const ApplyRecommendation = ({ ruleRow }: Props) => {
  const userPermissions = useUserPermissions();
  const styles = useStyles2(getStyles);
  const page = useCurrentPage();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { data: currentRulesData } = useRules();
  const { mutateAsync: checkRulesAsync } = useCheckRulesMutation();
  const { mutateAsync: updateRulesAsync } = useUpdateRulesMutation();
  const { setRuleSelection } = useSelectedItems(page);

  if (!currentRulesData) {
    return null;
  }

  const ruleKey: Symbol = getRuleKey(ruleRow);
  const action = ruleRow.recommended_action || 'unknown';
  const existingRule = currentRulesData.mappedItems.get(ruleKey);
  const getTooltipContent = (
    recommendation: AggregationRecommendation & { existingRule?: AggregationRule },
    isUrgent: boolean
  ) => {
    switch (recommendation.recommended_action) {
      case 'add':
        return (
          <div className={styles.tooltipStyling}>
            {isUrgent && <div>{URGENT_RECOMMENDATION}</div>}
            <div>
              {!userPermissions.canApplyRecommendations
                ? `You do not have permission for this action.`
                : `Add this rule per recommendation.`}
            </div>
          </div>
        );
      case 'keep':
        return (
          <div className={styles.tooltipStyling}>
            <div>The currently applied rule matches our recommendation. Nothing to apply.</div>
          </div>
        );
      case 'remove':
        return (
          <div className={styles.tooltipStyling}>
            {isUrgent && <div>{URGENT_RECOMMENDATION}</div>}
            <div>
              {!userPermissions.canApplyRecommendations
                ? `You do not have permission for this action.`
                : `Remove this rule per recommendation.`}
            </div>
          </div>
        );
      case 'update':
        const diff: Array<keyof AggregationRule> = [];

        if (existingRule) {
          diff.push(...ruleDiff(existingRule, recommendation));
        }

        return (
          <div className={styles.tooltipStyling}>
            {!userPermissions.canApplyRecommendations ? (
              `You do not have permission for this action.`
            ) : (
              <>
                {isUrgent && <div>{URGENT_RECOMMENDATION}</div>}
                {diff.length && (
                  <div>The rule properties [{diff.join(', ')}] will be updated with this recommendation.</div>
                )}
                <div>Update this rule per recommendation.</div>
              </>
            )}
          </div>
        );
      default:
        return (
          <div className={styles.tooltipStyling}>
            <div>The recommended action is unknown. Refresh the page, or contact support.</div>
          </div>
        );
    }
  };

  const isUrgent = (ruleRow.summary.usages_in_rules || 0) > 0;
  const recommendationIcon = actionIcons[ruleRow.recommended_action || 'unknown'];

  const handleClick = async () => {
    if (action === 'keep' || action === 'unknown') {
      warningAlert('Recommendation already applied or recommendation unknown');
      return;
    }

    const existingRules = Array.from(currentRulesData.mappedItems.values() || []);
    const rule = ruleRowToRuleJson(ruleRow);

    let updatedRules: AggregationRule[];

    if (action === 'remove') {
      updatedRules = removeRule(existingRules, rule);
    } else {
      updatedRules = addOrUpdateRule(existingRules, rule);
    }

    reportInteraction('g_adaptive_metrics_app_rule_apply', {
      action: action === 'add' ? ruleRow.recommended_action : 'remove',
      metric: ruleRow.metric,
      page,
    });

    await checkRulesAsync(updatedRules);
    await updateRulesAsync({ eTag: currentRulesData.eTag, rules: updatedRules });
    await setRuleSelection(getRuleKey(rule), false);
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <Button
        className={isUrgent ? styles.urgentButton : ''}
        icon={isUrgent ? 'exclamation-triangle' : recommendationIcon}
        size={'sm'}
        variant={'secondary'}
        tooltip={getTooltipContent(ruleRowToRecommendationJson(ruleRow), isUrgent)}
        disabled={
          !userPermissions.canApplyRecommendations
            ? true
            : ruleRow.recommended_action === 'keep' || ruleRow.recommended_action === 'unknown'
        }
        onClick={() => {
          setIsConfirmModalOpen(true);
        }}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        title={'Apply recommendation?'}
        body={`This will ${action} the rule for ${ruleRow.metric}`}
        confirmText={_capitalize(action)}
        onConfirm={handleClick}
        onDismiss={() => setIsConfirmModalOpen(false)}
        confirmButtonVariant={action === 'remove' ? 'destructive' : 'primary'}
      />
    </>
  );
};
