import { useLocation, useNavigate } from 'react-router-dom';

import { usePageFilters } from '@/hooks/context-hooks';
import { PageType } from '@/types';
import { paths } from '@/util/constants';
import { pageTypeFromRoute } from '@/util/methods';

export const useCurrentPage = (): PageType => {
  const pathname = useLocation().pathname;

  return pageTypeFromRoute(pathname.replace(paths.appRoot + '/', ''));
};

export const useGoToRecommendation = (metricName: string) => {
  const { setMetricFilter } = usePageFilters('recommendations');
  const navigate = useNavigate();
  return () => {
    setMetricFilter(metricName);
    navigate(paths.appRecommendations);
  };
};
