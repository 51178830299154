import React, { useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { InteractiveTable, useStyles2 } from '@grafana/ui';

import { useCurrentPage, usePageFilters, useRecommendedActionFilter, useSelectedItems, useTableData } from '@/hooks';
import { RuleRow } from '@/types';
import { PAGE_SIZE } from '@/util/constants';
import { getRowId, getRuleKey } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    checkbox: css({
      left: theme.spacing(5.5),
      position: 'absolute',
      top: theme.spacing(1.375),
    }),
    container: css({
      position: 'relative',
    }),
    noRows: css({
      color: theme.colors.text.secondary,
      fontStyle: 'italic',
      margin: `${theme.spacing(2)} auto 0`,
    }),
    pagination: css({
      alignSelf: 'flex-end',
      float: 'none',
      marginTop: theme.spacing(1),
    }),
  };
};

type Props = {
  // TODO #218 Fix types
  className?: string;
  columns: any;
  data: any;
  noRowsMessage?: string;
  pageSize?: number;
  subComponent?: (item: any) => React.ReactNode;
};
export const TableWithCheckbox = ({
  className,
  columns,
  data,
  noRowsMessage,
  pageSize = PAGE_SIZE,
  subComponent,
}: Props) => {
  const styles = useStyles2(getStyles);
  const page = useCurrentPage();
  const { isFiltered, metricFilter, showOnlySelected } = usePageFilters(page);
  const { selectedItems } = useSelectedItems(page);
  const { setTableData } = useTableData(page);

  const [memoizedTableData, setMemoizedTableData] = useState<RuleRow[]>([]);

  const { recommendedActionFilter } = useRecommendedActionFilter();

  const filteredData = useMemo(() => {
    const isFilterEmpty = _isEmpty(metricFilter);

    return data
      .filter((item: RuleRow) => {
        if (isFilterEmpty) {
          return true;
        }
        return item.metric.includes(metricFilter);
      })
      .filter((rule: RuleRow) => {
        const result = !showOnlySelected ? true : selectedItems.has(getRuleKey(rule));
        return result;
      })
      .filter((rule: RuleRow) => {
        if (recommendedActionFilter === 'all' || page === 'rules') {
          return true;
        } else {
          return rule.recommended_action === recommendedActionFilter;
        }
      });
  }, [data, metricFilter, page, recommendedActionFilter, selectedItems, showOnlySelected]);

  useEffect(() => {
    // By doing a deep compare of the filteredData and memoizedTableData, we avoid the table re-rendering sending the
    // user back to the first page when selecting an item (or anything that would cause the filteredData useMemo to fire.
    if (!_isEqual(memoizedTableData, filteredData)) {
      setMemoizedTableData(filteredData);
    }
    setTableData(filteredData);
  }, [filteredData]); // eslint-disable-line react-hooks/exhaustive-deps

  const noDataMessage = `No ${page === 'recommendations' ? 'recommended ' : ''}${page !== 'configuration' ? 'rules' : 'exemptions'} available ${isFiltered || recommendedActionFilter !== 'all' ? 'for the current filter' : ''}`;

  return (
    <>
      {subComponent ? (
        <InteractiveTable
          className={className}
          columns={columns}
          data={memoizedTableData}
          getRowId={getRowId}
          renderExpandedRow={subComponent}
          pageSize={pageSize}
          showExpandAll={true}
        />
      ) : (
        <InteractiveTable
          className={className}
          columns={columns}
          data={memoizedTableData}
          getRowId={getRowId}
          renderExpandedRow={subComponent}
          pageSize={pageSize}
        />
      )}
      {!filteredData.length && <div className={styles.noRows}>{noRowsMessage ? noRowsMessage : noDataMessage}</div>}
    </>
  );
};
