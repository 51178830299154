import React from 'react';

import { Card } from '@grafana/ui';

export function NoRules() {
  return (
    <Card>
      <Card.Heading>
        <span data-testid="no-rules">There are no rules currently applied</span>
      </Card.Heading>
      <Card.Description>
        <div>
          Go to the <a href="a/grafana-adaptive-metrics-app/recommendations">Recommended rules</a> tab.
        </div>
      </Card.Description>
    </Card>
  );
}
