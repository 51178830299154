import React, { useMemo } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Column, useStyles2 } from '@grafana/ui';

import { ContentBox } from '../ContentBox';
import { ExemptionSummary } from '../ExemptionSummary';
import { Exemption } from '@/api/types';
import { ExemptionActionButtons } from '@/components/ExemptionActionButtons';
import { ExemptionsHeader } from '@/components/PageHeader/ExemptionsHeader';
import { QueryResultHeader } from '@/components/QueryResultHeader';
import { TableWithCheckbox } from '@/components/TableWithCheckbox';
import { useExemptions, useUserPermissions } from '@/hooks';

const getStyles = (theme: GrafanaTheme2) => {
  const bg = config.theme2.colors.background;
  return {
    allMetric: css({
      color: theme.colors.text.secondary,
      fontStyle: 'italic',
    }),
    metricColumn: css({
      width: theme.spacing(30),
    }),
    metricEllipsis: css({
      display: 'inline-block',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    tableContainer: css({
      background: bg.primary,
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    }),
  };
};

type Styles = ReturnType<typeof getStyles>;

const getColumns = (styles: Styles, canUserEditExemption: boolean): Array<Column<Exemption>> => {
  return [
    {
      cell: ({ row: { original } }) => (
        <div className={styles.metricColumn}>
          {original.metric ? (
            <span className={styles.metricEllipsis}>{original.metric}</span>
          ) : (
            <span className={styles.allMetric}>All metrics</span>
          )}
        </div>
      ),
      disableGrow: true,
      header: 'Metric',
      id: 'metric',
    },
    {
      header: 'Reason',
      id: 'reason',
    },
    {
      cell: ({ row }) => {
        return canUserEditExemption && <ExemptionActionButtons exemption={row.original} />;
      },
      disableGrow: true,
      header: 'Actions',
      id: 'actions',
    },
  ];
};

export const ExemptionTable = () => {
  const styles = useStyles2(getStyles);
  const {
    data: exemptionsData,
    error: exemptionsError,
    isError: exemptionsIsError,
    isLoading: exemptionsIsLoading,
  } = useExemptions();
  const userPermissions = useUserPermissions();

  const columns = useMemo(
    () => getColumns(styles, userPermissions.canWriteExemptions),
    [styles, userPermissions.canWriteExemptions]
  );

  const tableData = useMemo(() => {
    if (!exemptionsData || exemptionsData?.length === 0) {
      return [];
    }

    return exemptionsData;
  }, [exemptionsData]);

  if (exemptionsIsLoading || !exemptionsData) {
    return (
      <QueryResultHeader
        errors={[exemptionsError]}
        isErrorArr={[exemptionsIsError]}
        isLoadingArr={[exemptionsIsLoading]}
      />
    );
  }

  const renderSubComponent = (exemption: Exemption) => {
    return <ExemptionSummary exemption={exemption} />;
  };

  return (
    <ContentBox>
      <ExemptionsHeader />
      <div className={styles.tableContainer}>
        <TableWithCheckbox columns={columns} data={tableData} subComponent={renderSubComponent} pageSize={5} />
      </div>
    </ContentBox>
  );
};
