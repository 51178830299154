import React, { useState } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Modal, useStyles2 } from '@grafana/ui';

import { CopyToClipboardButton } from '@/components/CopyToClipboardButton';
import { useRecommendations, useRules } from '@/hooks';
import { RuleRow } from '@/types';
import { getRuleKey, recommendationToRule } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => ({
  buttonContainer: css({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
});

type Props = {
  ruleRow: RuleRow;
};
export const CopyJson = ({ ruleRow }: Props) => {
  const styles = useStyles2(getStyles);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const { data: currentRulesData } = useRules();
  const { data: recommendationsData } = useRecommendations(true);

  const ruleKey: Symbol = getRuleKey(ruleRow);
  const existingRule = currentRulesData?.mappedItems.get(ruleKey);
  const recommendation = recommendationsData?.mappedItems.get(ruleKey);

  const recommendationDisabled =
    !recommendation || recommendation?.recommended_action === 'remove' || recommendation?.recommended_action === 'keep';
  const getRecommendationTooltip = () => {
    if (recommendationDisabled) {
      if (recommendation?.recommended_action === 'remove') {
        return 'Recommendation to remove nothing to copy';
      }
      return 'No recommended rule to copy';
    }
    return 'Copy recommended rule to clipboard';
  };

  const getAppliedRuleTooltip = () => {
    if (!existingRule) {
      return 'No applied rule to copy';
    }
    return 'Copy applied rule to clipboard';
  };

  return (
    <>
      <Button
        icon={'copy'}
        size={'sm'}
        variant={'secondary'}
        tooltip={'Copy JSON'}
        onClick={() => {
          setIsCopyModalOpen(true);
        }}
      />
      <Modal
        isOpen={isCopyModalOpen}
        title={'Copy JSON to clipboard'}
        closeOnEscape={true}
        closeOnBackdropClick={true}
        onDismiss={() => {
          setIsCopyModalOpen(false);
        }}
      >
        <div className={styles.buttonContainer}>
          <CopyToClipboardButton
            label={'Recommendation'}
            fill={'solid'}
            metricName={ruleRow.metric}
            textToCopy={recommendation ? JSON.stringify(recommendationToRule(recommendation)) : ''}
            disabled={recommendationDisabled}
            tooltip={getRecommendationTooltip()}
          />
          <CopyToClipboardButton
            label={'Applied rule'}
            fill={'solid'}
            metricName={ruleRow.metric}
            textToCopy={existingRule ? JSON.stringify(existingRule, null, 2) : ''}
            disabled={!existingRule}
            tooltip={getAppliedRuleTooltip()}
          />
        </div>
      </Modal>
    </>
  );
};
