import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { AddSegment } from '@/components/Configuration/Segments/add-segment';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  }),
});

export const SegmentsHeader = () => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.container}>
      <h3>Segments</h3>
      <div>
        <AddSegment />
      </div>
    </div>
  );
};
