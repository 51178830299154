import { trim as _trim } from 'lodash';

import { DataFrame, FieldType, LoadingState, MutableDataFrame, PanelData, TimeRange } from '@grafana/data';

import { QueryResult } from '@/api/types';

export const formatLabel = ({ metric }: QueryResult) => {
  let label = '';
  if (metric.__name__) {
    if (metric.__name__ === 'raw_series_agg_series_diff') {
      // Query now includes id modifier to only return configured stack data. No need to included uniqueifiers.
      return 'Time series reduced by applied rules';
    } else {
      label = metric.__name__;
    }
  }

  const uniqifiers = Object.entries(metric).reduce((res, [key, value]) => {
    if (key === '__name__') {
      return res;
    }
    return res + `${key}=${value}, `;
  }, '');

  if (uniqifiers.length) {
    return _trim(`${label} {${uniqifiers.slice(0, -2)}}`);
  }

  return _trim(label);
};

export const getTimeseriesPanelData = (queryRangeResults: QueryResult[] | undefined, timeRange: TimeRange) => {
  if (!queryRangeResults) {
    return;
  }
  let allFrames: DataFrame[] = [];
  queryRangeResults.forEach((result) => {
    // eslint-disable-next-line deprecation/deprecation
    const frame = new MutableDataFrame();
    const label = formatLabel(result);
    frame.addField({
      name: 'Time',
      type: FieldType.time,
      values: result.values.map((value) => {
        return value[0] * 1000;
      }),
    });

    frame.addField({
      config: {
        color: {
          mode: 'palette-classic',
        },
        custom: {
          fillOpacity: 25,
          showPoints: 'never',
        },
        mappings: [],
        min: 0,
        unit: 'short',
      },
      labels: {
        name: label,
      },
      name: 'Value',
      type: FieldType.number,
      values: result.values.map((value) => {
        return parseInt(value[1], 10);
      }),
    });

    allFrames.push(frame);
  });

  const panelData: PanelData = {
    series: allFrames,
    state: LoadingState.Done,
    timeRange: timeRange,
  };

  return panelData;
};
