import React, { ReactNode, useMemo } from 'react';

import { css } from '@emotion/css';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import Skeleton from 'react-loading-skeleton';

import { NavModelItem, PageLayoutType } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Alert, Icon, Tab, TabsBar, useTheme2 } from '@grafana/ui';

import { GrafanaVersionGrid } from '@/components/GrafanaVersionGrid';
import { useCurrentPage, useUserPermissions } from '@/hooks';
import { pages } from '@/pages/App/PageDefinitions';
import { paths } from '@/util/constants';
import { isGrafanaVersionRecommended, isGrafanaVersionSupported } from '@/util/grafana';
import { pageDefinitionToRoute } from '@/util/methods';

export const PageWrapperSkeleton = () => {
  const theme = useTheme2();
  return (
    <div
      data-testid={'page-wrapper-skeleton'}
      className={css`
        padding: ${theme.spacing(2)};
      `}
    >
      <Skeleton height={theme.spacing(20)} />
      <Skeleton height={theme.spacing(20)} />
      <Skeleton count={15} height={theme.spacing(4)} />
    </div>
  );
};

const AdaptiveMetricsTabs = () => {
  const currentPage = useCurrentPage();
  const combinedRulesPage = useBooleanFlagValue('combine_rules_page', false);

  const overviewPage = pages.get('overview');
  const rulesPage = pages.get('rules');
  const recommendationsPage = pages.get('recommendations');
  const configurationPage = pages.get('configuration');
  const ruleManagementPage = pages.get('ruleManagement');

  return (
    <TabsBar>
      {overviewPage && (
        <Tab
          key={`tab__${overviewPage.id}`}
          label={overviewPage.text}
          href={`${paths.appRoot}/${pageDefinitionToRoute(overviewPage)}`}
          active={currentPage === overviewPage.id}
        />
      )}
      {!combinedRulesPage && recommendationsPage && (
        <Tab
          key={`tab__${recommendationsPage.id}`}
          label={recommendationsPage.text}
          href={`${paths.appRoot}/${pageDefinitionToRoute(recommendationsPage)}`}
          active={currentPage === recommendationsPage.id}
        />
      )}
      {!combinedRulesPage && rulesPage && (
        <Tab
          key={`tab__${rulesPage.id}`}
          label={rulesPage.text}
          href={`${paths.appRoot}/${pageDefinitionToRoute(rulesPage)}`}
          active={currentPage === rulesPage.id}
        />
      )}
      {combinedRulesPage && ruleManagementPage && (
        <Tab
          key={`tab__${ruleManagementPage.id}`}
          label={ruleManagementPage.text}
          href={`${paths.appRoot}/${pageDefinitionToRoute(ruleManagementPage)}`}
          active={currentPage === ruleManagementPage.id}
        />
      )}
      {configurationPage && (
        <Tab
          key={`tab__${configurationPage.id}`}
          label={configurationPage.text}
          href={`${paths.appRoot}/${pageDefinitionToRoute(configurationPage)}`}
          active={currentPage === configurationPage.id}
        />
      )}
    </TabsBar>
  );
};

type Props = {
  children: ReactNode;
  logo: string;
};
export default function PageWrapper({ children, logo }: Props): React.JSX.Element {
  const userPermissions = useUserPermissions();
  // This controls the header content: icon, title, subtitle.
  // We ensure it appears in the `<PluginPage>`
  const pageNav = useMemo(
    () =>
      ({
        hideFromBreadcrumbs: true,
        img: logo,
        subTitle: (
          <>
            Metrics analysis and recommendations to reduce your cardinality.{' '}
            <a
              className={'external-link'}
              target="_blank"
              href="https://grafana.com/docs/grafana-cloud/data-configuration/metrics/manage-metrics-costs-via-adaptive-metrics/#manage-metrics-costs-via-adaptive-metrics"
              rel="noreferrer"
            >
              Documentation <Icon size="sm" name="external-link-alt" />
            </a>
          </>
        ) as any,
        text: 'Adaptive Metrics',
      }) as NavModelItem,
    [logo]
  );

  return (
    <PluginPage pageNav={pageNav} layout={PageLayoutType.Standard}>
      {isGrafanaVersionSupported() && !isGrafanaVersionRecommended() && (
        <Alert title="Update or upgrade for better experience" severity="warning">
          For the best Adaptive Metrics experience, update your Grafana installation.
          <GrafanaVersionGrid />
        </Alert>
      )}
      <div className={'adaptive-metrics-app'}>
        {isGrafanaVersionSupported() && userPermissions.canAccessPlugin && <AdaptiveMetricsTabs />}
        {children}
      </div>
    </PluginPage>
  );
}
