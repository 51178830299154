import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { trim as _trim } from 'lodash';

import { GrafanaTheme2, IconName } from '@grafana/data';
import { Field, Icon, Modal, Tooltip, useStyles2 } from '@grafana/ui';

import { useCurrentPage, useRecommendations, useRules } from '@/hooks';
import { PageType } from '@/types';
import { RECOMMENDATIONS_OUT_OF_SYNC } from '@/util/constants';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    icon: css({
      verticalAlign: 'text-top',
    }),
    /* To make a regular text field have the same dimensions as an Input component */
    inputSizeText: css({
      height: theme.spacing(4),
      marginTop: theme.spacing(1),
    }),
    label: css({
      fontSize: theme.typography.bodySmall.fontSize,
      lineHeight: theme.spacing(2),
    }),
    yellowIcon: css({
      color: theme.colors.warning.text,
    }),
  };
};

const lastUpdatedLabels: Record<PageType, string> = {
  configuration: '',
  overview: '',
  recommendations: 'Recommended rules last updated',
  ruleManagement: '',
  rules: 'Rules last saved',
};

const lastUpdatedTooltips: Record<PageType, string> = {
  configuration: '',
  overview: '',
  recommendations:
    'This timestamp indicates when these recommended rules were generated. Metrics and labels added since then will not be reflected in the recommendations.',
  ruleManagement: '',
  rules: 'This timestamp indicates when the rules were last updated.',
};

const LastUpdatedLabel = ({ inSync }: { inSync: boolean }) => {
  const styles = useStyles2(getStyles);
  const page = useCurrentPage();

  let icon: IconName = page === 'recommendations' && !inSync ? 'exclamation-triangle' : 'info-circle';
  let content: React.ReactNode = (
    <div>
      {page === 'recommendations' && !inSync && <p>{RECOMMENDATIONS_OUT_OF_SYNC}</p>}
      <p>{lastUpdatedTooltips[page]}</p>
      {page === 'recommendations' && <p>Recommended rules are sorted by series reduction, greatest to least.</p>}
    </div>
  );

  return (
    <label className={styles.label}>
      {lastUpdatedLabels[page]}{' '}
      <Tooltip theme="info-alt" content={content} placement="bottom-end">
        <Icon data-testid={`icon-${icon}`} className={styles.icon} name={icon} />
      </Tooltip>
    </label>
  );
};

export const LastUpdatedInfo = () => {
  const styles = useStyles2(getStyles);
  const page = useCurrentPage();
  const { data: currentRules } = useRules();
  const { data: recommendations } = useRecommendations(false);

  const lastUpdated = useMemo(() => {
    if (!page || !currentRules || !recommendations) {
      return null;
    }
    return page === 'rules' ? currentRules.lastModified : recommendations.lastModified;
  }, [page, currentRules, recommendations]);

  if (!currentRules || !recommendations) {
    return null;
  }

  return (
    <Field label={<LastUpdatedLabel inSync={_trim(currentRules.eTag) === _trim(recommendations.rulesVersion)} />}>
      <div className={styles.inputSizeText}>{lastUpdated}</div>
    </Field>
  );
};

type ModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};
export const LastUpdatedModal = ({ isOpen, onDismiss }: ModalProps) => {
  const { data: currentRules } = useRules();
  const { data: recommendations } = useRecommendations(false);

  const lastUpdated = useMemo(() => {
    if (!currentRules && !recommendations) {
      return null;
    }
    return { currenRules: currentRules?.lastModified, recommendations: recommendations?.lastModified };
  }, [currentRules, recommendations]);

  return (
    <Modal
      isOpen={isOpen}
      title={'Last updated information'}
      onDismiss={onDismiss}
      closeOnBackdropClick={true}
      closeOnEscape={true}
    >
      <dl>
        <dt>Applied Rules</dt>
        <dd>{lastUpdated?.currenRules}</dd>
        <dt>Recommendations</dt>
        <dd>{lastUpdated?.recommendations}</dd>
      </dl>
    </Modal>
  );
};
