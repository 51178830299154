import React from 'react';

import { QueryClientProvider } from '@tanstack/react-query';

import { AppPluginMeta, PluginConfigPageProps } from '@grafana/data';
import { Alert, Card, Field } from '@grafana/ui';

import { AggregationsConfig } from './AggregationsConfig';
import { AdaptiveMetricsContextProvider } from '@/context/adaptive-metrics-context';
import { queryClient } from '@/util/state';

export type AppPluginSettings = { baseUrl: string; instance: number };

export interface AppConfigProps extends PluginConfigPageProps<AppPluginMeta<AppPluginSettings>> {}

export const AppConfig = ({ plugin }: AppConfigProps) => {
  const { enabled, jsonData } = plugin.meta;

  return (
    <AdaptiveMetricsContextProvider instance={jsonData?.instance}>
      <QueryClientProvider client={queryClient}>
        {!enabled && (
          <Alert title="Plugin app disabled" severity="warning">
            <div>
              The Adaptive Metrics app plugin is disabled. This means that the pages, from which you can view parts of
              the Adaptive Metrics service such as rules and recommendations, are not enabled by Grafana. This disabled
              state has no effect on the Adaptive Metrics service itself.
            </div>
            <div>The plugin app can be enabled through the {`"Enable"`} button above.</div>
          </Alert>
        )}

        <Card>
          <Card.Heading>Instrumented metrics</Card.Heading>
          <Card.Description>
            {jsonData ? (
              <>
                <Field label="Metrics URL">
                  <span>{jsonData.baseUrl}</span>
                </Field>
                <Field label="Instance">
                  <span>{jsonData.instance}</span>
                </Field>
              </>
            ) : (
              <span>Unavailable</span>
            )}
          </Card.Description>
        </Card>

        <AggregationsConfig />
      </QueryClientProvider>
    </AdaptiveMetricsContextProvider>
  );
};
