import React from 'react';

import { RuleRow } from '@/types';
import { MATCH_TYPE_DESCRIPTION } from '@/util/constants';

export const MetricCell = ({ row: { match_type, metric } }: { row: RuleRow }) => {
  return (
    <span>
      {match_type !== 'exact' ? <strong>{MATCH_TYPE_DESCRIPTION[match_type]}: </strong> : null}
      {match_type !== 'exact' && <>&ldquo;</>}
      {metric}
      {match_type !== 'exact' && <>&rdquo;</>}
    </span>
  );
};
