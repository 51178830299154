import React from 'react';

import { RuleRow } from '@/types';

export const SeriesImpactCell = ({
  ruleRow: {
    summary: { total_series_after_aggregation, total_series_before_aggregation },
  },
}: {
  ruleRow: RuleRow;
}) => {
  if (!total_series_before_aggregation || !total_series_after_aggregation) {
    return null;
  }
  const diff = total_series_before_aggregation - total_series_after_aggregation;
  const percentage = diff !== 0 ? Math.abs(Math.floor((diff / total_series_before_aggregation) * 100)) : 0;

  return (
    <div data-testid={'series-impact-cell'}>
      {percentage}% ({diff * -1})
    </div>
  );
};
