import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import SVG from 'react-inlinesvg';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, LinkButton, useStyles2, useTheme2 } from '@grafana/ui';

import { ContentBox } from '@/components/ContentBox';
import { RawVsAggregated } from '@/components/RawVsAggregated';
import { useRecommendations, useRules } from '@/hooks';
import { paths } from '@/util/constants';
import { compareRule } from '@/util/methods';

export function Overview() {
  const theme = useTheme2();
  const styles = useStyles2(getWelcomePageStyles);

  const { data: rulesData } = useRules();
  const { data: recommendationsData } = useRecommendations(true);

  const combinedRulesPage = useBooleanFlagValue('combine_rules_page', false);

  const showRecommendationsAlert = useMemo(() => {
    if (rulesData && recommendationsData) {
      for (const [ruleSymbol, recommendation] of recommendationsData.mappedItems) {
        const existingRule = rulesData.mappedItems.get(ruleSymbol);

        if (!existingRule || !compareRule(existingRule, recommendation, true)) {
          return true;
        }
      }
    }
    return false;
  }, [rulesData, recommendationsData]);

  return (
    <div className={styles.grid}>
      {showRecommendationsAlert && (
        <Alert
          title={`Your metrics were last analyzed ${recommendationsData?.lastModified}. No new rule recommendations were generated. All your rule recommendations are currently up-to-date.`}
          severity={'info'}
          className={styles.fullSpan}
        >
          <div className={styles.alertContent}>
            <span>These rules will optimize your rule set.</span>
            <LinkButton
              href={`a/grafana-adaptive-metrics-app/${combinedRulesPage ? 'rule-management' : 'recommendations'}`}
              fill={'outline'}
            >
              Review recommended rules
            </LinkButton>
          </div>
        </Alert>
      )}
      {rulesData && rulesData.items.length > 0 && (
        <ContentBox className={styles.fullSpan}>
          <RawVsAggregated />
        </ContentBox>
      )}
      <ContentBox className={styles.fullSpan}>
        <h3>What is Adaptive Metrics?</h3>
        <ul className={styles.list}>
          <li>
            Adaptive Metrics is a cardinality optimization feature that allows you to identify and eliminate unused time
            series metrics data by means of aggregation.
          </li>
          <li>Recommended rules identify what metrics to aggregate based on usage within your cloud environment.</li>
        </ul>
      </ContentBox>

      <ContentBox className={styles.fullSpan}>
        <h3>How it works</h3>
        <div style={{ textAlign: 'center' }}>
          <SVG
            style={{ maxHeight: theme.spacing(16) }}
            src={`${paths.pluginPublic}/img/how-it-works.svg`}
            width={undefined}
            height={undefined}
          />
        </div>
      </ContentBox>

      <ContentBox className={styles.gettingStartedResponsive}>
        <div>
          <h2>Get started</h2>
          Adaptive Metrics recommends rules for aggregation.
        </div>
        <div>
          <h3>GUI workflow</h3>
          <ol className={styles.list}>
            <li>Review the most current recommended rules.</li>
            <li>Decide which rules to apply.</li>
            <li>Apply them.</li>
          </ol>
        </div>
        <div>
          <h3>API workflow</h3>
          <ol className={styles.list}>
            <li>Download the recommended rules JSON file.</li>
            <li>Review and make any changes.</li>
            <li>Upload the JSON file.</li>
          </ol>
        </div>
      </ContentBox>

      <ContentBox className={styles.videoBlockResponsive}>
        <iframe
          title="Alerting - Introductory video"
          src="https://player.vimeo.com/video/824917190?transparent=0"
          width="960"
          height="540"
          allow="autoplay; fullscreen"
          allowFullScreen
          frameBorder="0"
          // This is necessary because color-scheme defined on :root has impact on iframe elements
          // More about how color-scheme works for iframes https://github.com/w3c/csswg-drafts/issues/4772
          // Summary: If the color scheme of an iframe differs from embedding document iframe gets an opaque canvas bg appropriate to its color scheme
          style={{ colorScheme: 'light dark' }}
        ></iframe>
      </ContentBox>
    </div>
  );
}

const getWelcomePageStyles = (theme: GrafanaTheme2) => ({
  alertContent: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  fullSpan: css({
    gridColumn: '1 / span 4',
  }),
  gettingStartedResponsive: css({
    '@media (max-width: 900px)': {
      gridColumn: '1 / span 4',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    // half span from start
    gridColumn: '1 / span 2',
  }),
  grid: css({
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: 'min-content auto',
    marginTop: theme.spacing(2),
  }),
  list: css({
    '& > li': {
      marginBottom: theme.spacing(1),
    },
    margin: theme.spacing(0, 2),
  }),
  padBottom: css({
    paddingBottom: theme.spacing(2),
  }),
  videoBlockResponsive: css({
    '@media (max-width: 900px)': {
      gridColumn: '1 / span 4',
    },
    // half span from end
    gridColumn: '3 / span 2' /* 16:9 */,

    iframe: {
      border: 'none',
      height: `calc(100% - ${theme.spacing(4)})`,
      left: theme.spacing(2),
      position: 'absolute',
      top: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)})`,
    },
    padding: '56.25% 0 0 0',

    // Video required
    position: 'relative',
  }),
});
