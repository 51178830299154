import React from 'react';

import { Alert } from '@grafana/ui';

import { ExternalLink } from '@/components/ExternalLink';

export function AdaptiveMetricsNotConfigured() {
  return (
    <Alert title="Why am I not seeing any recommendations?" severity="warning">
      <div>The recommendations service shows that recommendations have never been generated.</div>
      <div>
        Have you{' '}
        <ExternalLink href="https://grafana.com/products/cloud/metrics/prometheus-cardinality-optimization/?pg=docs-grafana-cloud-manage-metrics-costs-via-adaptive-metrics&plcmt=in-txt#signup">
          signed up for access to Adaptive Metrics in Grafana Cloud
        </ExternalLink>
        ?
      </div>
    </Alert>
  );
}
