import React, { useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { capitalize as _capitalize, isUndefined as _isUndefined } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, ButtonVariant, Checkbox, Column, ConfirmModal, InteractiveTable, useStyles2 } from '@grafana/ui';

import { ConfirmationData, TableRow } from '@/components/ConfirmationModal/types';
import { applyConfirmationData, confirmationDataToTableRows } from '@/components/ConfirmationModal/util-functions';
import { LimitationTooltip } from '@/components/LimitationTooltip';
import {
  useCheckRulesMutation,
  useCurrentPage,
  useRecommendations,
  useRules,
  useSelectedItems,
  useUpdateRulesMutation,
} from '@/hooks';
import { errorAlert } from '@/util/alert';
import { downloadJson } from '@/util/download';
import { getRowId, noop } from '@/util/methods';

const INITIAL_ACTIONS = ['add', 'remove', 'update'];
const PREVIEW_BUTTON = 'Preview applied rules';

const getColumns = (
  selectedActions: Set<string>,
  setSelectedActions: (selectedActions: Set<string>) => void
): Array<Column<TableRow>> => {
  return [
    {
      cell: ({
        row: {
          original: { action, quantity },
        },
      }) => {
        return (
          <Checkbox
            onChange={(v) => {
              if (v.currentTarget.checked) {
                selectedActions.add(action);
              } else {
                selectedActions.delete(action);
              }

              setSelectedActions(new Set<string>(selectedActions));
            }}
            checked={selectedActions.has(action) && quantity !== 0}
            disabled={quantity === 0}
          />
        );
      },
      disableGrow: true,
      id: 'rule-selector',
    } as Column<TableRow>,
    {
      cell: ({ row }) => {
        return _capitalize(row.original.action);
      },
      header: 'Action',
      id: 'action',
    },
    {
      header: 'Number of rules',
      id: 'quantity',
    },
    {
      cell: ({ row }) => {
        if (row.original.seriesEffect === undefined) {
          return 'Unknown';
        } else {
          return row.original.seriesEffect;
        }
      },
      header: 'Change to time series',
      id: 'seriesEffect',
    },
  ];
};

const getStyles = (theme: GrafanaTheme2) => ({
  previewButton: css({
    marginTop: theme.spacing(2),
  }),
});

type Props = {
  confirmText: string;
  data: ConfirmationData;
  isOpen: boolean;
  isRemoveAllRules?: boolean;
  isUpdatesModal?: boolean;
  // Whether to ignore showing "all" if selected rules is empty
  onConfirm: () => void;
  onDismiss: () => void;
  title: string;
  variant?: ButtonVariant;
};

export const ConfirmationModal = ({
  confirmText,
  data,
  isOpen,
  isRemoveAllRules,
  isUpdatesModal = false,
  onConfirm,
  onDismiss,
  title,
  variant = 'primary',
}: Props) => {
  const page = useCurrentPage();
  const { data: currentRulesData } = useRules();
  const { data: recommendationsData } = useRecommendations(false);
  const { clearSelection } = useSelectedItems(page);
  const { selectedItems } = useSelectedItems(page);
  const [selectedActions, setSelectedActions] = useState(new Set<string>());

  const { isLoading: isLoadingCheckRules, mutateAsync: checkRulesAsync } = useCheckRulesMutation();
  const { isLoading: isLoadingUpdateRules, mutateAsync: updateRulesAsync } = useUpdateRulesMutation();

  const styles = useStyles2(getStyles);

  const tableData: TableRow[] = useMemo(() => {
    return confirmationDataToTableRows(data);
  }, [data]);

  useEffect(() => {
    tableData.forEach((item) => {
      if (item.quantity > 0 && INITIAL_ACTIONS.includes(item.action)) {
        selectedActions.add(item.action);
      }
    });

    setSelectedActions(new Set<string>(selectedActions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const onPreviewDownload = useMemo(() => {
    if (!currentRulesData || !recommendationsData) {
      return async () => {
        errorAlert('An error occurred. Try again later.');
      };
    }

    return async () => {
      const ruleMap = applyConfirmationData(
        data,
        currentRulesData.mappedItems,
        recommendationsData.mappedItems,
        selectedActions
      );
      const rulesArray = Array.from(ruleMap.values());

      await checkRulesAsync(rulesArray);
      downloadJson(rulesArray, 'preview');
    };
  }, [currentRulesData, recommendationsData, data, selectedActions, checkRulesAsync]);

  const confirmAction = useMemo(() => {
    if (!currentRulesData || !recommendationsData) {
      return async () => {
        errorAlert('An error occurred. Try again later.');
      };
    }

    return async () => {
      const ruleMap = applyConfirmationData(
        data,
        currentRulesData.mappedItems,
        recommendationsData.mappedItems,
        selectedActions
      );
      const rulesArray = Array.from(ruleMap.values());

      await checkRulesAsync(rulesArray);
      await updateRulesAsync({ eTag: currentRulesData.eTag, rules: rulesArray });
      clearSelection();
      onConfirm();
    };
  }, [
    currentRulesData,
    recommendationsData,
    data,
    selectedActions,
    checkRulesAsync,
    updateRulesAsync,
    clearSelection,
    onConfirm,
  ]);

  const busy = isLoadingCheckRules || isLoadingUpdateRules;
  const totalCount = tableData.reduce((acc, { quantity }) => acc + quantity, 0);
  const considerCount = selectedItems.size > 0 && totalCount !== selectedItems.size;

  return (
    <ConfirmModal
      isOpen={isOpen}
      title={title}
      body={
        <div>
          {isRemoveAllRules ? (
            <>
              <div>
                {data.remove.ruleKeys.size} rule{data.remove.ruleKeys.size > 1 ? 's' : ''} will be removed.
              </div>
              <Button
                className={styles.previewButton}
                onClick={onPreviewDownload}
                size="sm"
                variant="secondary"
                icon="download-alt"
              >
                {PREVIEW_BUTTON}
              </Button>
            </>
          ) : (
            <>
              <p>
                {isUpdatesModal && considerCount ? <>Of {selectedItems.size} selected rules, </> : null}
                {totalCount} change{totalCount > 1 ? 's' : ''} will be applied to the rules. <LimitationTooltip />
              </p>
              <InteractiveTable
                columns={getColumns(selectedActions, setSelectedActions)}
                data={tableData}
                getRowId={getRowId}
              />
              <Button
                className={styles.previewButton}
                onClick={onPreviewDownload}
                size="sm"
                variant="secondary"
                icon="download-alt"
              >
                {PREVIEW_BUTTON}
              </Button>
            </>
          )}
        </div>
      }
      confirmButtonVariant={busy ? 'secondary' : variant}
      confirmText={confirmText}
      onConfirm={busy ? noop : confirmAction}
      onDismiss={busy ? noop : onDismiss}
    />
  );
};
