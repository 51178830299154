import { SelectableValue } from '@grafana/data';

export const validateExemptionForm = (
  selectAllMetric: boolean,
  selectAllLabels: boolean,
  selectedLabels?: Array<SelectableValue<string>>,
  selectedMetric?: SelectableValue<string>,
  disableRecommendations?: boolean
) => {
  const isAllMetricsAndLabelsSelected = selectAllMetric && selectAllLabels;
  const isAllMetricsSelectedAndDisableRecommendationOn = selectAllMetric && disableRecommendations;
  const isLabelSelectedAndDisabledRecommendationOn = selectedLabels && selectedLabels.length && disableRecommendations;
  const isMetricEmpty = !selectedMetric?.value && !selectAllMetric;
  const isLabelEmpty = selectedLabels && !selectedLabels.length && !selectAllLabels;
  const areBothEmpty = isMetricEmpty && isLabelEmpty;

  if (isAllMetricsSelectedAndDisableRecommendationOn) {
    return 'All metrics cannot be set when disabling recommendations';
  } else if (isLabelSelectedAndDisabledRecommendationOn) {
    return 'Labels cannot be defined when disabling recommendations';
  } else if (isAllMetricsAndLabelsSelected) {
    return 'Both metric and labels cannot be set to all';
  } else if (areBothEmpty) {
    return 'Both metric and labels cannot be empty';
  } else {
    let errorMsg = '';
    if (isMetricEmpty) {
      errorMsg = 'Metric cannot be empty';
    }
    if (isLabelEmpty && !disableRecommendations) {
      errorMsg = 'Labels cannot be empty';
    }
    return errorMsg;
  }
};

export const handleMetric = (
  setSelectedMetric: (metric: SelectableValue<string>) => void,
  setSelectAllMetric: (allSelected: boolean) => void,
  metric?: string
) => {
  if (metric) {
    setSelectedMetric({ label: metric, value: metric });
  } else {
    setSelectedMetric({ label: 'All metrics', value: undefined });
    setSelectAllMetric(true);
  }
};

export const handleLabels = (
  setSelectedLabels: (labels: Array<SelectableValue<string>>) => void,
  setSelectAllLabels: (allSelected: boolean) => void,
  disable_recommendations?: boolean,
  keep_labels?: string[]
) => {
  if (keep_labels) {
    setSelectedLabels(keep_labels.map((label) => ({ label, value: label })));
  } else {
    setSelectAllLabels(!disable_recommendations);
  }
};

export const handleReason = (setExemptionReason: (reason: string) => void, reason?: string) => {
  if (reason) {
    setExemptionReason(reason);
  }
};
