import React, { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { some as _some, values as _values } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { Button, ConfirmModal, InlineSwitch, RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { AggregationRule } from '@/api/types';
import { ConfirmationModal } from '@/components/ConfirmationModal';
import { ActionData, ConfirmationData } from '@/components/ConfirmationModal/types';
import { ContentBox } from '@/components/ContentBox';
import { FilterField } from '@/components/PageHeader/FilterField';
import { LastUpdatedInfo } from '@/components/PageHeader/LastUpdatedInfo';
import { reportBatch } from '@/components/PageHeader/util';
import { hasRecommendation } from '@/components/RecommendationIndicator';
import {
  useCurrentPage,
  usePageFilters,
  useRecommendations,
  useRules,
  useSelectedItems,
  useUserPermissions,
} from '@/hooks';
import type { PageType } from '@/types';
import { warningAlert } from '@/util/alert';
import { DIVISIONS, LARGE_SPAN } from '@/util/constants';
import { downloadJson } from '@/util/download';
import { isApplied, recommendationToRule } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => {
  const REMAINING_SPAN = DIVISIONS - LARGE_SPAN;
  return {
    alignContentRight: css({
      justifyContent: 'flex-end',
    }),
    alignSelfBottom: css({
      alignSelf: 'flex-end',
    }),
    applyButtons: css({
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    }),
    base: css({
      display: 'grid',
      gridTemplateColumns: '3fr 2fr',
      marginTop: theme.spacing(1),
    }),
    buttons: css({
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      justifyContent: 'space-between',
    }),
    filters: css({
      display: 'grid',
      gridGap: theme.spacing(1),
      gridTemplateColumns: `repeat(${DIVISIONS}, 1fr)`,
    }),
    flexRowContent: css({
      display: 'flex',
      gap: theme.spacing(1),
    }),
    flexWrap: css({
      flexWrap: 'wrap',
    }),
    fullSpan: css({
      gridColumn: `span ${DIVISIONS}`,
    }),
    icon: css({
      verticalAlign: 'text-top',
    }),
    /* To make a regular text field have the same dimensions as an Input component */
    inputSizeText: css({
      height: theme.spacing(4),
      marginTop: theme.spacing(1),
    }),
    label: css({
      fontSize: theme.typography.bodySmall.fontSize,
      lineHeight: theme.spacing(2),
    }),
    largeSpan: css({
      gridColumn: `span ${LARGE_SPAN}`,
    }),
    radioButtonListContainer: css({
      marginTop: theme.spacing(2),
    }),
    remainingSpan: css({
      gridColumn: `span ${REMAINING_SPAN}`,
    }),
    selectedText: css({
      color: theme.colors.text.secondary,
      display: 'inline-block',
      fontStyle: 'italic',
      marginRight: theme.spacing(1),
    }),
  };
};

const downloadTooltips: Record<PageType, string> = {
  configuration: '',
  overview: '',
  recommendations: 'Download the latest recommended aggregation rules',
  ruleManagement: '',
  rules: 'Download the current rules',
};

const defaultDownloadOption = [
  { label: 'All', value: 'all' },
  { label: 'Selected', value: 'selected' },
];

const recommendationType = [
  { label: 'Verbose', value: 'verbose' },
  { label: 'Non-verbose', value: 'nonVerbose' },
];

export function PageHeader() {
  const page = useCurrentPage();

  const [batchApplyModalIsOpen, setBatchApplyModalIsOpen] = useState(false);
  const [applyUpdatesModalIsOpen, setApplyUpdatesModalIsOpen] = useState(false);

  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
  const [downloadVerboseNonVerbose, setDownloadVerboseNonVerbose] = useState<string>('verbose');
  const [downloadAllSelected, setDownloadAllSelected] = useState('all');

  const userPermissions = useUserPermissions();
  const styles = useStyles2(getStyles);
  const { data: currentRules } = useRules();
  const { data: recommendationsVerbose } = useRecommendations(true);
  const { data: recommendationsNonVerbose } = useRecommendations(false);
  const { clearSelection, selectedItems } = useSelectedItems(page);
  const { showOnlySelected, showOnlyWithRecommendations, toggleShowOnlySelected, toggleShowOnlyWithRecommendations } =
    usePageFilters(page);

  const applyUpdatesData = useMemo(() => {
    const updates: ConfirmationData = {
      add: { ruleKeys: new Set<Symbol>() } as ActionData,
      keep: { ruleKeys: new Set<Symbol>() } as ActionData,
      remove: { ruleKeys: new Set<Symbol>() } as ActionData,
      unknown: { ruleKeys: new Set<Symbol>() } as ActionData,
      update: { ruleKeys: new Set<Symbol>() } as ActionData,
    };
    if (currentRules && recommendationsVerbose) {
      if (page === 'rules') {
        (selectedItems.size ? selectedItems : Array.from(currentRules.mappedItems.keys())).forEach((ruleKey) => {
          const currentRule = currentRules.mappedItems.get(ruleKey);
          const recommendation = recommendationsVerbose.mappedItems.get(ruleKey);

          if (hasRecommendation(currentRule, recommendation)) {
            const rec = recommendationsVerbose.mappedItems.get(ruleKey);
            if (rec) {
              const updt = updates[rec.recommended_action];
              updt.ruleKeys.add(ruleKey);
              if (rec.total_series_before_aggregation && rec.total_series_after_aggregation) {
                updt.seriesBefore = (updt.seriesBefore || 0) + rec.total_series_before_aggregation;
                updt.seriesAfter = (updt.seriesAfter || 0) + rec.total_series_after_aggregation;
              }
            }
          }
        });
      }
    }
    return updates;
  }, [currentRules, page, recommendationsVerbose, selectedItems]);

  const applyUpdatesDisabled = useMemo(() => {
    if (applyUpdatesData) {
      return !_some(_values(applyUpdatesData), (actionData: ActionData) => actionData.ruleKeys.size > 0);
    }
    return true;
  }, [applyUpdatesData]);

  const batchActionData = useMemo(() => {
    const data: ConfirmationData = {
      add: { ruleKeys: new Set<Symbol>() } as ActionData,
      keep: { ruleKeys: new Set<Symbol>() } as ActionData,
      remove: { ruleKeys: new Set<Symbol>() } as ActionData,
      unknown: { ruleKeys: new Set<Symbol>() } as ActionData,
      update: { ruleKeys: new Set<Symbol>() } as ActionData,
    };
    if (currentRules && recommendationsVerbose) {
      if (page === 'rules') {
        (selectedItems.size ? selectedItems : Array.from(currentRules.mappedItems.keys())).forEach((symbol) => {
          data['remove'].ruleKeys.add(symbol);
        });
      } else {
        (selectedItems.size ? selectedItems : Array.from(recommendationsVerbose.mappedItems.keys())).forEach(
          (symbol) => {
            const rec = recommendationsVerbose?.mappedItems.get(symbol);

            if (rec) {
              const curr = currentRules?.mappedItems.get(symbol);
              const recApplied = isApplied(curr, recommendationToRule(rec), rec?.recommended_action === 'remove');

              if (!recApplied) {
                const dataAction = data[rec.recommended_action];
                dataAction.ruleKeys.add(symbol);

                if (rec.total_series_before_aggregation && rec.total_series_after_aggregation) {
                  dataAction.seriesBefore = (dataAction.seriesBefore || 0) + rec.total_series_before_aggregation;
                  dataAction.seriesAfter = (dataAction.seriesAfter || 0) + rec.total_series_after_aggregation;
                }
              }
            }
          }
        );
      }
    }
    return data;
  }, [currentRules, page, recommendationsVerbose, selectedItems]);

  const batchActionDisabled = useMemo(() => {
    if (batchActionData) {
      return !_some(_values(batchActionData), (actionData: ActionData) => actionData.ruleKeys.size > 0);
    }
    return true;
  }, [batchActionData]);

  const resetDownloadModal = () => {
    setDownloadAllSelected('all');
    setDownloadVerboseNonVerbose('verbose');
    setDownloadModalIsOpen(false);
  };

  const isApplyAll = !selectedItems.size;

  const primaryButtonText = (() => {
    if (page === 'rules') {
      return `Remove ${!isApplyAll ? 'selected rule' + (selectedItems.size > 1 ? 's' : '') : 'all rules'}`;
    } else {
      return `Apply ${
        !isApplyAll ? 'selected recommendation' + (selectedItems.size > 1 ? 's' : '') : 'all recommendations'
      }`;
    }
  })();

  const updateButtonText = (() => {
    if (!isApplyAll) {
      return `Apply selected update${selectedItems.size > 1 ? 's' : ''}`;
    } else {
      return 'Apply all updates';
    }
  })();

  const onDownload = useMemo(() => {
    let data: Map<Symbol, AggregationRule> | undefined;
    if (page === 'rules') {
      data = currentRules?.mappedItems;
    } else {
      data =
        downloadVerboseNonVerbose === 'verbose'
          ? recommendationsVerbose?.mappedItems
          : recommendationsNonVerbose?.mappedItems;
    }

    if (data) {
      let downloadData = [
        ...(downloadAllSelected === 'all'
          ? data
          : new Map([...data].filter(([key, _]) => selectedItems.has(key)))
        ).values(),
      ];
      return () => {
        if (downloadData.length) {
          const isVerboseRecommendations = page === 'recommendations' && downloadVerboseNonVerbose === 'verbose';
          downloadJson(
            downloadData,
            `${page}${isVerboseRecommendations ? '-verbose' : ''}-${downloadAllSelected}-${Date.now()}.json`
          );
          reportInteraction('g_adaptive_metrics_app_download_metric_rules_json', {
            isDownloadAll: downloadAllSelected === 'all',
            quantitySelected: selectedItems.size,
            rulesOrRecommendations: page,
          });
          resetDownloadModal();
        } else {
          if (downloadAllSelected === 'selected') {
            warningAlert(
              `Selected rows are only available as ${page === 'recommendations' ? 'applied rules' : 'recommendations'}. Choose 'All' or change your selection.`
            );
          }
        }
      };
    }

    return () => {
      warningAlert('No data available to download. Refresh the page and try again.');
      resetDownloadModal();
    };
  }, [
    page,
    downloadAllSelected,
    downloadVerboseNonVerbose,
    recommendationsNonVerbose,
    recommendationsVerbose,
    currentRules,
    selectedItems,
  ]);

  return (
    <ContentBox className={styles.base}>
      <div className={styles.filters}>
        <FilterField className={styles.largeSpan} />
        <div className={cx(styles.fullSpan, styles.flexRowContent, styles.flexWrap)}>
          <InlineSwitch
            label={`Show selected ${page}`}
            showLabel={true}
            transparent={true}
            value={showOnlySelected}
            onChange={() => toggleShowOnlySelected()}
          />

          {page === 'rules' && (
            <InlineSwitch
              label={'Show available updates'}
              showLabel={true}
              transparent={true}
              value={showOnlyWithRecommendations}
              onChange={() => toggleShowOnlyWithRecommendations()}
            />
          )}
        </div>
        <div className={cx(styles.fullSpan, styles.flexRowContent)}>
          <div>
            <span className={styles.selectedText}>{`${selectedItems.size} ${
              page === 'rules'
                ? `rule${selectedItems.size !== 1 ? 's' : ''}`
                : `recommendation${selectedItems.size !== 1 ? 's' : ''}`
            } selected`}</span>
            {selectedItems.size > 0 && (
              <Button
                data-testid={'clear-selection-button'}
                variant={'primary'}
                fill={'text'}
                onClick={clearSelection}
                icon={'times-circle'}
                tooltip={'Clear selection'}
              />
            )}
          </div>
        </div>
      </div>
      <div className={cx(styles.flexRowContent, styles.alignContentRight)}>
        <LastUpdatedInfo />

        <div className={styles.buttons}>
          <>
            <Button
              data-testid={'pageheader-download'}
              variant={'secondary'}
              icon={'file-download'}
              tooltip={downloadTooltips[page]}
              onClick={() => setDownloadModalIsOpen(true)}
            />
            <ConfirmModal
              isOpen={downloadModalIsOpen}
              title={page === 'rules' ? 'Download rules' : 'Download recommendations'}
              body={
                <>
                  <RadioButtonGroup
                    options={defaultDownloadOption}
                    fullWidth
                    disabledOptions={!selectedItems.size ? ['selected'] : undefined}
                    onChange={(value) => setDownloadAllSelected(value)}
                    value={downloadAllSelected}
                  />

                  {page === 'recommendations' && (
                    <div className={styles.radioButtonListContainer}>
                      <RadioButtonGroup
                        fullWidth
                        options={recommendationType}
                        value={downloadVerboseNonVerbose}
                        onChange={(v) => setDownloadVerboseNonVerbose(v)}
                      />
                    </div>
                  )}
                </>
              }
              confirmText="Download"
              onConfirm={onDownload}
              confirmButtonVariant="primary"
              onDismiss={() => {
                resetDownloadModal();
              }}
            />
          </>

          {(userPermissions.canApplyRecommendations || userPermissions.canDeleteRules) && (
            <span className={styles.applyButtons}>
              {page === 'rules' && userPermissions.canApplyRecommendations && (
                <>
                  <Button
                    disabled={applyUpdatesDisabled}
                    tooltip={
                      applyUpdatesDisabled ? 'To apply updates, select rules that have updates available.' : undefined
                    }
                    variant={'primary'}
                    onClick={() => setApplyUpdatesModalIsOpen(!applyUpdatesModalIsOpen)}
                  >
                    {updateButtonText}
                  </Button>
                  <ConfirmationModal
                    confirmText={'Apply'}
                    data={applyUpdatesData}
                    isOpen={applyUpdatesModalIsOpen}
                    onConfirm={() => {
                      reportBatch('g_adaptive_metrics_app_batch_update', applyUpdatesData, page, isApplyAll, true);
                      setApplyUpdatesModalIsOpen(false);
                    }}
                    onDismiss={() => {
                      setApplyUpdatesModalIsOpen(false);
                    }}
                    isUpdatesModal={true}
                    title={'Change rules?'}
                  />
                </>
              )}
              {((page === 'rules' && userPermissions.canDeleteRules) ||
                (page === 'recommendations' && userPermissions.canApplyRecommendations)) && (
                <>
                  <Button
                    disabled={batchActionDisabled}
                    variant={page === 'rules' ? 'destructive' : 'primary'}
                    onClick={() => setBatchApplyModalIsOpen(!batchApplyModalIsOpen)}
                  >
                    {primaryButtonText}
                  </Button>
                  <ConfirmationModal
                    confirmText={'Apply'}
                    data={batchActionData}
                    isOpen={batchApplyModalIsOpen}
                    onConfirm={() => {
                      reportBatch('g_adaptive_metrics_app_batch_apply', batchActionData, page, isApplyAll, false);
                      setBatchApplyModalIsOpen(false);
                    }}
                    onDismiss={() => {
                      setBatchApplyModalIsOpen(false);
                    }}
                    isRemoveAllRules={page === 'rules'}
                    title={'Change rules?'}
                  />
                </>
              )}
            </span>
          )}
        </div>
      </div>
    </ContentBox>
  );
}
