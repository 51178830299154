import { isEqual as _isEqual } from 'lodash';
import { satisfies } from 'semver';

import { OrgRole } from '@grafana/data';
import { config } from '@grafana/runtime';

import { PluginPermissions } from '@/types';

export const MINIMUM_GRAFANA_VERSION = '9.4.0';
export const RECOMMENDED_GRAFANA_VERSION = '10.0.0';

export function isGrafanaVersionSupported() {
  const [version] = config.buildInfo.version.split('-');

  return satisfies(version, `>= ${MINIMUM_GRAFANA_VERSION}`);
}

export function isGrafanaVersionRecommended() {
  const [version] = config.buildInfo.version.split('-');

  return satisfies(version, `>= ${RECOMMENDED_GRAFANA_VERSION}`);
}

const isUserActionAllowed = (permission: PluginPermissions, fallbackOrgRole: OrgRole) => {
  const { orgRole: userOrgRole, permissions: userPermissions } = config.bootData.user;
  return config.featureToggles.accessControlOnCall
    ? !!userPermissions?.[permission]
    : _isEqual(userOrgRole, fallbackOrgRole);
};

export const getUserPermissions = () => {
  return {
    canAccessPlugin: isUserActionAllowed('grafana-adaptive-metrics-app.plugin:access', OrgRole.Admin),
    canApplyRecommendations: isUserActionAllowed('grafana-adaptive-metrics-app.recommendations:apply', OrgRole.Admin),
    canDeleteRules: isUserActionAllowed('grafana-adaptive-metrics-app.rules:delete', OrgRole.Admin),
    canReadExemptions: isUserActionAllowed('grafana-adaptive-metrics-app.exemptions:read', OrgRole.Admin),
    canReadRecommendations: isUserActionAllowed('grafana-adaptive-metrics-app.recommendations:read', OrgRole.Admin),
    canReadRules: isUserActionAllowed('grafana-adaptive-metrics-app.rules:read', OrgRole.Admin),
    canWriteExemptions: isUserActionAllowed('grafana-adaptive-metrics-app.exemptions:write', OrgRole.Admin),
    canWriteRules: isUserActionAllowed('grafana-adaptive-metrics-app.rules:write', OrgRole.Admin),
    hasPermission: (permission: PluginPermissions) => isUserActionAllowed(permission, OrgRole.Admin),
  };
};
