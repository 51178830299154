import { castArray as _castArray } from 'lodash';
import { lastValueFrom } from 'rxjs';

import { getBackendSrv } from '@grafana/runtime';

import { AggregationRule, isAggregationRuleLike, ListResponseWithHeaders } from './types';
import { FLAGSMITH_DEV_CLIENT_KEY, FLAGSMITH_PROD_CLIENT_KEY, paths } from '@/util/constants';
import { getRuleKey } from '@/util/methods';
import { isDev } from '@/util/state';
import { formatDateTime } from '@/util/time';

export async function getWithHeaders<T>(url: string, params?: Record<string, unknown>) {
  const { data, headers } = await lastValueFrom(
    getBackendSrv().fetch<T>({ method: 'GET', params, showErrorAlert: false, url })
  );
  const lastModifiedHeader = headers.get('Last-Modified');
  let lastModified = '';
  if (lastModifiedHeader) {
    lastModified = formatDateTime(lastModifiedHeader);
  }

  const eTag = headers.get('ETag');
  const rulesVersion = headers.get('Rules-Version');

  const items = _castArray(data);
  const mappedItems = new Map<Symbol, T>();

  if (items.length) {
    if (isAggregationRuleLike(items[0])) {
      items.forEach((rule) => {
        mappedItems.set(getRuleKey(rule as AggregationRule), rule);
      });
    } else {
      items.forEach((obj) => {
        mappedItems.set(Symbol.for(JSON.stringify(obj)), obj);
      });
    }
  }

  return {
    eTag: eTag || '',
    items,
    lastModified,
    mappedItems,
    rulesVersion: rulesVersion || '',
  } as ListResponseWithHeaders<T>;
}

export const updateFlagsmithIdentity = async (identifier: string, _traits: { [k: string]: unknown }) => {
  const traits = Object.entries(_traits).map(([key, value]) => ({
    trait_key: key,
    trait_value: value,
  }));
  await getBackendSrv().post(
    paths.flagsIdentityUpdate,
    { identifier, traits },
    {
      headers: {
        'x-environment-key': isDev() ? FLAGSMITH_DEV_CLIENT_KEY : FLAGSMITH_PROD_CLIENT_KEY,
      },
      showErrorAlert: false,
      showSuccessAlert: false,
    }
  );
};
