import React, { Suspense } from 'react';

import { SkeletonTheme } from 'react-loading-skeleton';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AppRootProps } from '@grafana/data';
import { useTheme2 } from '@grafana/ui';

import { AppPluginSettings } from '../AppConfig';
import { AccessDenied } from '@/components/AccessDenied';
import { ContextProviders } from '@/components/ContextProviders';
import PageWrapper, { PageWrapperSkeleton } from '@/components/PageWrapper';
import { useUserPermissions } from '@/hooks';
import { pages } from '@/pages/App/PageDefinitions';
import { paths } from '@/util/constants';
import { pageDefinitionToRoute } from '@/util/methods';

export const App = (props: AppRootProps<AppPluginSettings>) => {
  const userPermissions = useUserPermissions();
  const theme = useTheme2();

  return (
    <SkeletonTheme
      baseColor={theme.colors.background.secondary}
      highlightColor={theme.colors.emphasize(theme.colors.background.secondary)}
      borderRadius={theme.shape.radius.default}
    >
      <ContextProviders props={props}>
        <Suspense fallback={<PageWrapperSkeleton />}>
          <PageWrapper logo={props.meta.info.logos.large}>
            <Routes>
              {Array.from(pages.values()).map((pageDefinition) => {
                const { component: Component, id, reqAction } = pageDefinition;
                return (
                  <Route
                    key={id}
                    path={`/${pageDefinitionToRoute(pageDefinition)}`}
                    element={userPermissions.hasPermission(reqAction) ? <Component /> : <AccessDenied />}
                  />
                );
              })}
              <Route key="root" path={'*'} element={<Navigate to={`${paths.appRoot}/overview`} />} />
            </Routes>
          </PageWrapper>
        </Suspense>
      </ContextProviders>
    </SkeletonTheme>
  );
};
