import { reportInteraction } from '@grafana/runtime';

import { ConfirmationData } from '@/components/ConfirmationModal/types';
import { PageType } from '@/types';

export const reportBatch = (
  key: string,
  data: ConfirmationData,
  page: PageType,
  isApplyAll = false,
  applyingUpdatesOnRulesPage = false
) => {
  const addCount = data.add.ruleKeys.size;
  const removeCount = data.remove.ruleKeys.size;
  const updateCount = data.update.ruleKeys.size;

  reportInteraction(key, {
    addCount,
    applyingUpdatesOnRulesPage,
    isApplyAll,
    page,
    removeCount,
    updateCount,
  });
};
