import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';

import { SelectableValue } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import {
  AsyncMultiSelect,
  AsyncSelect,
  Button,
  CollapsableSection,
  ConfirmModal,
  Field,
  MultiSelect,
  RadioButtonGroup,
} from '@grafana/ui';

import { getWithHeaders } from '@/api';
import { Aggregation, AggregationRule, aggregationTypeValues, MatchType } from '@/api/types';
import {
  useCheckRulesMutation,
  useCurrentPage,
  useRules,
  useSelectedItems,
  useUpdateRulesMutation,
  useUserPermissions,
} from '@/hooks';
import { RuleRow } from '@/types';
import { errorAlert, warningAlert } from '@/util/alert';
import { paths } from '@/util/constants';
import { addOrUpdateRule, getRuleKey, noop, removeRule, ruleRowToRuleJson } from '@/util/methods';

const ruleToSelectableValues = (
  rule?: AggregationRule
): {
  aggregations?: Array<SelectableValue<Aggregation>>;
  drop_labels?: Array<SelectableValue<string>>;
  keep_labels?: Array<SelectableValue<string>>;
  metric?: SelectableValue<string>;
} => {
  if (rule) {
    const result: {
      aggregations?: Array<SelectableValue<Aggregation>>;
      drop_labels?: Array<SelectableValue<string>>;
      keep_labels?: Array<SelectableValue<string>>;
      metric?: SelectableValue<string>;
    } = {
      metric: { label: rule.metric, value: rule.metric },
    };

    if (rule.aggregations) {
      const aggregations: Array<SelectableValue<Aggregation>> = [];
      rule.aggregations.forEach((aggregation) => {
        aggregations.push({ label: aggregation, value: aggregation });
      });
      result.aggregations = aggregations;
    }
    if (rule.drop_labels) {
      const drops: Array<SelectableValue<string>> = [];
      rule.drop_labels.forEach((label) => {
        drops.push({ label, value: label });
      });
      result.drop_labels = drops;
    }

    if (rule.keep_labels) {
      const keeps: Array<SelectableValue<string>> = [];
      rule.keep_labels.forEach((label) => {
        keeps.push({ label, value: label });
      });
      result.keep_labels = keeps;
    }

    return result;
  } else {
    return {
      drop_labels: undefined,
      keep_labels: undefined,
      metric: undefined,
    };
  }
};

type Props = {
  ruleRow?: RuleRow;
};

export const EditRule = ({ ruleRow }: Props) => {
  const userPermissions = useUserPermissions();
  const page = useCurrentPage();
  const { setRuleSelection } = useSelectedItems(page);
  const [isOpen, setIsOpen] = useState(false);
  const [invalidFields, setInvalidFields] = useState<Set<'aggregations' | 'labels' | 'metric'>>(new Set());
  const { data: currentRulesData } = useRules();
  const currentRule = ruleRow ? currentRulesData?.mappedItems.get(getRuleKey(ruleRow)) : undefined;
  const ruleSelectableValues = ruleRow ? ruleToSelectableValues(currentRule) : {};

  const [selectedMetric, setSelectedMetric] = useState<SelectableValue<string> | undefined>();
  const [selectedLabels, setSelectedLabels] = useState<Array<SelectableValue<string>> | undefined>();
  const [selectedAggregations, setSelectedAggregations] = useState<Array<SelectableValue<string>> | undefined>();
  const [dropOrKeepLabels, setDropOrKeepLabels] = useState<'drop' | 'keep'>('drop');
  const [aggregateOrDropMetric, setAggregateOrDropMetric] = useState<'aggregate' | 'drop'>(
    currentRule?.drop ? 'drop' : 'aggregate'
  );
  const [matchType, setMatchType] = useState<MatchType>(currentRule?.match_type || 'exact');

  const { isLoading: isLoadingCheckRules, mutateAsync: checkRulesAsync } = useCheckRulesMutation();
  const { isLoading: isLoadingUpdateRules, mutateAsync: updateRulesAsync } = useUpdateRulesMutation();

  const busy = isLoadingCheckRules || isLoadingUpdateRules;

  useEffect(() => {
    setInvalidFields(new Set());
    setSelectedMetric(ruleSelectableValues.metric);
    setSelectedLabels(
      ruleSelectableValues.keep_labels ? ruleSelectableValues.keep_labels : ruleSelectableValues.drop_labels
    );
    setDropOrKeepLabels(ruleSelectableValues.keep_labels ? 'keep' : 'drop');
    setAggregateOrDropMetric(currentRule?.drop ? 'drop' : 'aggregate');
    setMatchType(currentRule?.match_type || 'exact');
    setSelectedAggregations(ruleSelectableValues.aggregations);
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadAsyncData = async (path: string, query: string) => {
    const data = await getWithHeaders<{ data: string[] }>(path);

    return data.items[0].data
      .map((each) => ({ label: each, value: each }))
      .filter((each) => each.label.includes(query));
  };

  const formHasErrors = () => {
    invalidFields.clear();
    let hasErrors = false;
    const labels: string[] = selectedLabels?.filter((label) => Boolean(label.value)).map((label) => label.value!) || [];
    const aggregations = selectedAggregations?.map((aggregation) => aggregation.value as Aggregation) || [];

    if (!selectedMetric?.value) {
      errorAlert('Metric cannot be empty');
      hasErrors = true;
      invalidFields.add('metric');
    }
    if (aggregateOrDropMetric === 'aggregate') {
      if (aggregations.length <= 0) {
        errorAlert('Aggregations cannot be empty');
        hasErrors = true;
        invalidFields.add('aggregations');
      }
      if (labels.length <= 0) {
        errorAlert('Labels cannot be empty');
        hasErrors = true;
        invalidFields.add('labels');
      }
    }
    if (hasErrors) {
      setInvalidFields(new Set(invalidFields));
    } else {
      setInvalidFields(new Set());
    }
    return hasErrors;
  };

  const onDelete = async () => {
    if (!ruleRow) {
      warningAlert('No rule to remove');
      return;
    }

    if (!currentRulesData) {
      errorAlert('Rules not loaded, refresh page to reload');
      return;
    }

    const existingRules = Array.from(currentRulesData?.mappedItems.values() || []);
    const rule = ruleRowToRuleJson(ruleRow);

    let updatedRules: AggregationRule[] = removeRule(existingRules, rule);

    reportInteraction('g_adaptive_metrics_app_rule_apply', {
      action: 'remove',
      metric: ruleRow.metric,
      page,
    });

    await checkRulesAsync(updatedRules);
    await updateRulesAsync({ eTag: currentRulesData.eTag, rules: updatedRules });
    await setRuleSelection(getRuleKey(rule), false);
    setIsOpen(false);
  };

  const onConfirm = async () => {
    if (currentRulesData) {
      if (!formHasErrors()) {
        const existingRules = Array.from(currentRulesData.mappedItems.values() || []);
        const labels: string[] =
          selectedLabels?.filter((label) => Boolean(label.value)).map((label) => label.value!) || [];
        const aggregations = selectedAggregations?.map((aggregation) => aggregation.value as Aggregation) || [];

        const labelsProp = dropOrKeepLabels === 'drop' ? { drop_labels: labels } : { keep_labels: labels };

        const rule: AggregationRule = {
          ...(aggregateOrDropMetric === 'aggregate' ? { aggregations, ...labelsProp } : { drop: true }),
          match_type: matchType,
          metric: selectedMetric!.value!,
        };

        const updatedRules = addOrUpdateRule(existingRules, rule, ruleRow ? getRuleKey(ruleRow) : undefined);

        await checkRulesAsync(updatedRules);
        await updateRulesAsync({ eTag: currentRulesData.eTag, rules: updatedRules });

        setIsOpen(false);
      }
    }
  };

  return (
    <>
      <Button
        disabled={!userPermissions.canWriteRules}
        icon={ruleRow ? 'pen' : undefined}
        size={ruleRow ? 'sm' : undefined}
        variant={'secondary'}
        tooltip={
          !userPermissions.canWriteRules
            ? `You don't have permission for this action`
            : ruleRow
              ? 'Edit applied rule'
              : 'Add new custom rule'
        }
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {ruleRow ? '' : 'Add new'}
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        title={ruleRow ? 'Edit rule' : 'Add rule'}
        body={
          <>
            <Field label={'Rule type'}>
              <RadioButtonGroup
                options={[
                  { label: 'Aggregation', value: 'aggregate' },
                  { label: 'Drop', value: 'drop' },
                ]}
                value={aggregateOrDropMetric}
                onChange={(value) => {
                  setAggregateOrDropMetric(value as 'aggregate' | 'drop');
                }}
              />
            </Field>
            <Field label={'Metric'} required={true}>
              <RadioButtonGroup
                options={[
                  { label: 'Exact', value: 'exact' },
                  { label: 'Prefix', value: 'prefix' },
                  { label: 'Suffix', value: 'suffix' },
                ]}
                value={matchType}
                onChange={(value: MatchType) => {
                  setMatchType(value);
                }}
              />
            </Field>
            <Field>
              <AsyncSelect
                inputId="metric-dropdown"
                aria-label="metric-dropdown"
                invalid={invalidFields.has('metric')}
                isClearable
                isSearchable
                defaultOptions
                loadOptions={(query) => loadAsyncData(paths.grafanaPromMetrics, query)}
                value={selectedMetric}
                onChange={(value) => {
                  setSelectedMetric(value);
                }}
                allowCustomValue
              />
            </Field>
            {aggregateOrDropMetric === 'aggregate' && (
              <>
                <Field label={'Aggregations'} required={true}>
                  <MultiSelect
                    options={aggregationTypeValues.map(
                      (value): SelectableValue<Aggregation> => ({ label: value, value })
                    )}
                    value={selectedAggregations}
                    onChange={(value) => {
                      setSelectedAggregations(value);
                    }}
                    invalid={invalidFields.has('aggregations')}
                    isClearable
                  />
                </Field>
                <Field label={'Labels'} required={true}>
                  <RadioButtonGroup
                    options={[
                      { label: 'Drop labels', value: 'drop' },
                      { label: 'Keep labels', value: 'keep' },
                    ]}
                    value={dropOrKeepLabels}
                    onChange={(value) => {
                      setDropOrKeepLabels(value as 'drop' | 'keep');
                    }}
                  />
                </Field>
                <Field>
                  <AsyncMultiSelect
                    inputId="label-dropdown"
                    aria-label="label-dropdown"
                    defaultOptions
                    invalid={invalidFields.has('labels')}
                    isSearchable
                    isClearable
                    loadOptions={(query) => loadAsyncData(paths.grafanaPromLabels, query)}
                    value={selectedLabels}
                    placeholder={'Choose labels'}
                    onChange={(value) => {
                      setSelectedLabels(value);
                    }}
                    allowCustomValue
                  />
                </Field>
              </>
            )}
            {ruleRow && (
              <CollapsableSection
                contentClassName={css`
                  padding: 8px;
                `}
                label={'Advanced'}
                isOpen={false}
              >
                <Field label={'Remove'} description={'Clicking this button will remove the rule immediately'}>
                  <Button variant={'destructive'} size={'sm'} icon={'trash-alt'} onClick={onDelete}>
                    Remove rule
                  </Button>
                </Field>
              </CollapsableSection>
            )}
          </>
        }
        confirmText={'Save'}
        confirmButtonVariant={busy ? 'secondary' : 'primary'}
        onConfirm={busy ? noop : onConfirm}
        onDismiss={
          busy
            ? noop
            : () => {
                setIsOpen(false);
              }
        }
      />
    </>
  );
};
