import React, { useState } from 'react';

import { css, cx } from '@emotion/css';
import { useQueryClient } from '@tanstack/react-query';
import { some as _some } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { Button, ConfirmModal, useStyles2 } from '@grafana/ui';

import { ConfirmModalLoader } from '../ConfirmModalLoader';
import { ExemptionBuilderModal } from '../ExemptionBuilderModal';
import {
  QUERY_KEYS,
  useCreateExemptionsMutation,
  useCurrentPage,
  useDeleteExemptionMutation,
  useDownloadExemption,
  useExemptions,
  useUserPermissions,
} from '@/hooks';
import { warningAlert } from '@/util/alert';
import { DIVISIONS, LARGE_SPAN } from '@/util/constants';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    alignContentRight: css({
      justifyContent: 'flex-end',
    }),
    applyButtons: css({
      alignItems: 'flex-start',
      display: 'flex',
      gap: theme.spacing(1),
    }),
    base: css({
      display: 'grid',
      gridTemplateColumns: '3fr 2fr',
      marginTop: theme.spacing(1),
    }),
    buttons: css({
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      justifyContent: 'space-between',
    }),
    exemptionHeader: css({
      lineHeight: 'normal',
      margin: 0,
    }),
    filters: css({
      display: 'grid',
      gridGap: theme.spacing(3),
      gridTemplateColumns: `repeat(${DIVISIONS}, 1fr)`,
    }),
    flexRowContent: css({
      display: 'flex',
      gap: theme.spacing(1),
    }),
    flexWrap: css({
      flexWrap: 'wrap',
    }),
    fullSpan: css({
      gridColumn: `span ${DIVISIONS}`,
    }),
    headerWrapper: css({
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(2),
    }),
    largeSpan: css({
      gridColumn: `span ${LARGE_SPAN}`,
    }),
    selectedText: css({
      color: theme.colors.text.secondary,
      display: 'inline-block',
      fontStyle: 'italic',
      marginRight: theme.spacing(1),
    }),
  };
};

export const ExemptionsHeader = () => {
  const styles = useStyles2(getStyles);
  const page = useCurrentPage();
  const userPermissions = useUserPermissions();
  const [createExemptionModalIsOpen, setCreateExemptionModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading: isExemptionCreating, mutateAsync: createExemptionAsync } = useCreateExemptionsMutation();
  const { isLoading: isExemptionDeleting, mutateAsync: deleteExemptionAsync } = useDeleteExemptionMutation(true);
  const { data: exemptions, isFetching: isFetchingExemption } = useExemptions();
  const downloadExemptions = useDownloadExemption();

  const onExemptionRemove = async () => {
    const exemptionsToDelete = exemptions?.map(({ id }) => id) || [];

    const results = await Promise.allSettled(exemptionsToDelete.map((uid) => deleteExemptionAsync(uid)));
    const anyFailed = _some(results, (result) => result.status === 'rejected');

    if (anyFailed) {
      warningAlert('Some exemptions failed to delete. Try again or contact support.', 'Deletion Failed');
    }

    reportInteraction('g_adaptive_metrics_app_exemption', { action: 'remove', page, removeCount: results.length });
    await queryClient.invalidateQueries([QUERY_KEYS.exemptions]);
  };

  const isDeleting = isExemptionDeleting || isFetchingExemption;

  return (
    <div className={styles.headerWrapper}>
      <h3 className={styles.exemptionHeader}>Exemptions</h3>
      <div className={cx(styles.flexRowContent, styles.alignContentRight)}>
        <div className={styles.buttons}>
          <span className={styles.applyButtons}>
            {userPermissions.canWriteExemptions && (
              <>
                <Button onClick={() => setCreateExemptionModalIsOpen(true)}>Add new</Button>
                <ExemptionBuilderModal
                  isOpen={createExemptionModalIsOpen}
                  onConfirm={createExemptionAsync}
                  onDismiss={() => setCreateExemptionModalIsOpen(false)}
                  isLoading={isExemptionCreating || isFetchingExemption}
                />
                <Button
                  disabled={!exemptions?.length}
                  variant={'destructive'}
                  onClick={() => setDeleteModalIsOpen(true)}
                >
                  Remove all
                </Button>
                <ConfirmModal
                  isOpen={deleteModalIsOpen}
                  title="Delete Exemptions?"
                  body={
                    <>
                      {exemptions?.length} exemption{exemptions?.length === 1 ? '' : 's'} will be removed
                    </>
                  }
                  confirmText={
                    isDeleting ? ((<ConfirmModalLoader text="Deleting..." />) as unknown as string) : 'Delete'
                  }
                  confirmButtonVariant="destructive"
                  onConfirm={async () => {
                    await onExemptionRemove();
                    setDeleteModalIsOpen(false);
                  }}
                  onDismiss={() => {
                    setDeleteModalIsOpen(false);
                  }}
                />
              </>
            )}
            <Button
              variant="secondary"
              icon="file-download"
              data-testid="exemption-download"
              onClick={downloadExemptions}
            />
          </span>
        </div>
      </div>
    </div>
  );
};
