import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { sortBy as _sortBy } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { Column, Icon, Tag, Tooltip, useStyles2 } from '@grafana/ui';

import { AdaptiveMetricsNotConfigured } from './AdaptiveMetricsNotConfigured';
import { AggregationRecommendation, AggregationRule } from '@/api/types';
import { ItemCheckbox } from '@/components/ItemCheckbox';
import { MetricCell } from '@/components/MetricCell';
import { PageHeader } from '@/components/PageHeader';
import { QueryResultHeader } from '@/components/QueryResultHeader';
import { RuleSummary } from '@/components/RuleSummary';
import { SeriesImpactCell } from '@/components/SeriesImpactCell';
import { TableHeaderCheckbox } from '@/components/TableHeaderCheckbox';
import { TableWithCheckbox } from '@/components/TableWithCheckbox';
import { useCurrentPage, useRecommendations, useRules } from '@/hooks';
import { RuleRow } from '@/types';
import { URGENT_RECOMMENDATION } from '@/util/constants';
import { convertToRuleRow, getRuleKey, isApplied, recommendationToRule } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    icon: css({
      marginLeft: theme.spacing(0.5),
      verticalAlign: 'text-top',
    }),
    keepsFilter: css({
      width: theme.spacing(30),
    }),
    pagination: css({
      alignSelf: 'flex-end',
      float: 'none',
      marginTop: theme.spacing(1),
    }),
    tableContainer: css({
      display: 'flex',
      flexDirection: 'column',
    }),
    urgentTagStyle: css({
      span: {
        backgroundColor: theme.colors.warning.transparent,
        border: `1px solid ${theme.colors.warning.border}`,
        color: theme.colors.getContrastText(theme.colors.warning.transparent),
      },
    }),
  };
};

type Styles = ReturnType<typeof getStyles>;

const getColumns = (styles: Styles): Array<Column<RuleRow>> => {
  return [
    {
      cell: ({ row }) => {
        const ruleRow: RuleRow = row.original;
        return <ItemCheckbox item={ruleRow} />;
      },
      disableGrow: true,
      header: (<TableHeaderCheckbox />) as unknown as string,
      id: 'rule-selector',
    } as Column<RuleRow>,
    {
      cell: ({ row }) => {
        const ruleRow: RuleRow = row.original;
        if ((ruleRow.summary.usages_in_rules || 0) > 0) {
          return (
            <Tooltip content={URGENT_RECOMMENDATION}>
              <span className={styles.urgentTagStyle} data-testid={'urgent-recommendation'}>
                <Tag name={''} icon={'exclamation-triangle'} />
              </span>
            </Tooltip>
          );
        }
        return null;
      },
      disableGrow: true,
      id: 'urgent-indicator',
    } as Column<RuleRow>,
    {
      cell: ({ row }) => {
        const ruleRow: RuleRow = row.original;
        return <MetricCell row={ruleRow} />;
      },
      header: 'Metric',
      id: 'metric',
    } as Column<RuleRow>,
    {
      cell: ({ row }) => {
        const ruleRow: RuleRow = row.original;
        return <SeriesImpactCell ruleRow={ruleRow} />;
      },
      header: (
        <>
          {'Series impact'}
          <Tooltip
            content={'If known, the impact on time series applying this rule will have for the affected metric.'}
            placement={'top'}
          >
            <Icon className={styles.icon} name={'info-circle'} />
          </Tooltip>
        </>
      ) as unknown as string,
      id: 'series-impact',
    } as Column<RuleRow>,
  ];
};

export const Recommendations = () => {
  const styles = useStyles2(getStyles);
  const page = useCurrentPage();

  const verboseRecommendations = true;

  const {
    data: currentRulesData,
    error: currentRulesError,
    isError: currentRulesIsError,
    isLoading: currentRulesIsLoading,
  } = useRules();

  const {
    data: recommendationsData,
    error: recommendationsError,
    isError: recommendationsIsError,
    isLoading: recommendationsIsLoading,
  } = useRecommendations(verboseRecommendations);

  const {
    data: recommendationsNonVerboseData,
    error: recommendationRulesError,
    isError: recommendationRulesIsError,
    isLoading: recommendationRulesIsLoading,
  } = useRecommendations(false);

  const columns = useMemo(() => getColumns(styles), [styles]);

  const mergedRuleData: Array<AggregationRecommendation & { existingRule?: AggregationRule }> = useMemo(() => {
    if (recommendationsData?.mappedItems && recommendationsData.mappedItems.size === 0) {
      return [];
    }

    const res: Array<AggregationRecommendation & { existingRule?: AggregationRule }> = [];
    recommendationsData?.mappedItems.forEach((recommendation) => {
      if (recommendation.recommended_action !== 'keep') {
        let toPush: AggregationRecommendation & { existingRule?: AggregationRule } = recommendation;

        const existingRule: AggregationRule | undefined = currentRulesData?.mappedItems.get(getRuleKey(recommendation));
        if (existingRule) {
          toPush = { ...toPush, existingRule };
        }

        res.push(toPush);
      }
    });

    return res;
  }, [currentRulesData, recommendationsData]);

  const updatesTableData = useMemo(() => {
    if (mergedRuleData.length === 0) {
      return [];
    }

    return _sortBy(
      mergedRuleData
        .filter((recommendation) => {
          return !isApplied(
            recommendation.existingRule,
            recommendationToRule(recommendation),
            Boolean(recommendation.recommended_action === 'remove')
          );
        })
        .map((rule): RuleRow => {
          return convertToRuleRow(rule, rule.recommended_action || 'unknown');
        }),
      (value) => -(value.summary.usages_in_rules || 0)
    );
  }, [mergedRuleData]);

  if (
    recommendationsIsLoading ||
    recommendationRulesIsLoading ||
    !recommendationsData ||
    !recommendationsNonVerboseData
  ) {
    return (
      <QueryResultHeader
        errors={[recommendationsError, recommendationRulesError, currentRulesError]}
        isErrorArr={[recommendationsIsError, recommendationRulesIsError, currentRulesIsError]}
        isLoadingArr={[recommendationsIsLoading, recommendationRulesIsLoading, currentRulesIsLoading]}
      />
    );
  }

  const renderSubComponent = (ruleRow: RuleRow) => {
    reportInteraction('g_adaptive_metrics_app_metric_rule_expanded', { metric: ruleRow.metric, page });
    return <RuleSummary ruleRow={ruleRow} />;
  };

  if (recommendationsData.mappedItems.size === 0 && !recommendationsData.lastModified) {
    return <AdaptiveMetricsNotConfigured />;
  }

  return (
    <div>
      <QueryResultHeader
        errors={[recommendationsError, currentRulesError]}
        isErrorArr={[recommendationsIsError, currentRulesIsError]}
        isLoadingArr={[recommendationsIsLoading, currentRulesIsLoading]}
      />
      <PageHeader />
      <div className={styles.tableContainer}>
        <TableWithCheckbox columns={columns} data={updatesTableData} subComponent={renderSubComponent} />
      </div>
    </div>
  );
};
