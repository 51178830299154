import React from 'react';

import { reportInteraction } from '@grafana/runtime';
import { Button, Menu } from '@grafana/ui';

import { useCopyToClipboard, useCurrentPage } from '@/hooks';
import { successAlert } from '@/util/alert';

type Props = {
  asMenuItem?: boolean;
  buttonVariant?: 'primary' | 'secondary' | undefined;
  className?: string;
  disabled?: boolean;
  errorLabel?: string;
  fill?: 'outline' | 'solid' | 'text';
  label?: string;
  metricName: string;
  size?: any;
  // The text to display after a successful copy-to-clipboard action
  successLabel?: string;
  textToCopy: string;
  tooltip?: string;
};

export const CopyToClipboardButton = ({
  asMenuItem = false,
  buttonVariant = 'secondary',
  className,
  disabled = false,
  fill = 'text',
  label,
  metricName,
  size = 'md',
  successLabel = 'Copied to clipboard',
  textToCopy,
  tooltip = 'Copy rule to clipboard',
}: Props) => {
  const page = useCurrentPage();
  const [isCopied, doCopy] = useCopyToClipboard(textToCopy);

  const shouldShowSuccessLabel = isCopied && successLabel;
  const buttonLabel = shouldShowSuccessLabel ? successLabel : label || '';
  const buttonIcon = shouldShowSuccessLabel ? 'check' : 'copy';

  const handleClick = () => {
    reportInteraction('g_adaptive_metrics_app_copy_metric_rule_to_clipboard', {
      metric: metricName,
      page,
    });
    doCopy();

    if (asMenuItem) {
      successAlert('Copied to clipboard');
    }
  };

  if (asMenuItem) {
    return <Menu.Item onClick={handleClick} label={buttonLabel} icon={buttonIcon} />;
  }

  return (
    <Button
      onClick={handleClick}
      className={className}
      fill={fill}
      variant={buttonVariant}
      icon={buttonIcon}
      size={size}
      tooltip={tooltip}
      tooltipPlacement={'top'}
      disabled={disabled}
    >
      {buttonLabel}
    </Button>
  );
};
