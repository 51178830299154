import React from 'react';

import { Icon, Tooltip } from '@grafana/ui';

export const LimitationTooltip = () => {
  return (
    <Tooltip content={'Change in your time series count is only calculated on new rules added'}>
      <Icon name={'question-circle'} />
    </Tooltip>
  );
};
