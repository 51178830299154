import { AppPlugin } from '@grafana/data';

import { App } from '@/pages/App';
import { AppConfig, AppPluginSettings } from '@/pages/AppConfig';
import { NoAccess } from '@/pages/NoAccess';
import { UnsupportedVersion } from '@/pages/UnsupportedVersion';
import { getUserPermissions, isGrafanaVersionSupported } from '@/util/grafana';

export const plugin = new AppPlugin<AppPluginSettings>();

if (process.env.NODE_ENV === 'development' && process.env.ENABLE_MSW) {
  const { worker } = require('./_mocks_/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
    serviceWorker: {
      options: {
        scope: '/a/',
      },
      url: '/public/msw/mockServiceWorker.js',
    },
  });
}

const userPermissions = getUserPermissions();

if (userPermissions.canAccessPlugin) {
  if (isGrafanaVersionSupported()) {
    plugin.setRootPage(App).addConfigPage({
      body: AppConfig,
      icon: 'cog',
      id: 'configuration',
      title: 'Configuration',
    });
  } else {
    plugin.setRootPage(UnsupportedVersion);
  }
} else {
  plugin.setRootPage(NoAccess);
}
