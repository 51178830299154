import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { capitalize as _capitalize, isEqual as _isEqual } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, ConfirmModal, useStyles2 } from '@grafana/ui';

import { AggregationRecommendation, AggregationRule } from '@/api/types';
import {
  useCheckRulesMutation,
  useCurrentPage,
  useGoToRecommendation,
  useRecommendations,
  useRules,
  useSelectedItems,
  useUpdateRulesMutation,
} from '@/hooks';
import { RuleRow } from '@/types';
import { URGENT_RECOMMENDATION } from '@/util/constants';
import { compareRule, getRuleKey, recommendationToRule, removeRule, ruleDiff, ruleRowToRuleJson } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => ({
  icon: css({
    color: theme.colors.success.text,
    margin: `auto ${theme.spacing(1.5)}`,
  }),
  tooltipStyling: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
  urgentButton: css({
    '&:hover': {
      backgroundColor: theme.colors.warning.transparent,
      border: `1px solid ${theme.colors.emphasize(theme.colors.warning.border, 0.25)}`,
    },
    backgroundColor: theme.colors.warning.transparent,
    border: `1px solid ${theme.colors.warning.border}`,
    color: theme.colors.getContrastText(theme.colors.warning.transparent),
  }),
});

export const hasRecommendation = (
  currentRule?: AggregationRule,
  matchingRecommendation?: AggregationRecommendation
) => {
  if (!currentRule || !matchingRecommendation) {
    return false;
  }

  if (matchingRecommendation.recommended_action === 'keep') {
    // if the recommended action is keep we don't want to show the indicator even if the rule data differs. This would
    // indicate that something is out of sync between the rule and the recommendation
    return false;
  }

  return (
    matchingRecommendation.recommended_action === 'remove' ||
    Boolean(!compareRule(currentRule, recommendationToRule(matchingRecommendation), true))
  );
};

type Props = {
  ruleRow: RuleRow;
};

export const RecommendationIndicator = ({ ruleRow }: Props) => {
  const styles = useStyles2(getStyles);
  const page = useCurrentPage();
  const { data: currentRulesData } = useRules();
  const [isRemoveConfirmOpen, setIsRemoveConfirmOpen] = useState(false);
  const { mutateAsync: checkRulesAsync } = useCheckRulesMutation();
  const { mutateAsync: updateRulesAsync } = useUpdateRulesMutation();
  const { setRuleSelection } = useSelectedItems(page);
  const goToRecommendation = useGoToRecommendation(ruleRow.metric);

  // we use the verbose recommendations here because they will include 'remove' type actions
  const { data: recommendationsVerboseData } = useRecommendations(true);

  const ruleKey: Symbol = getRuleKey(ruleRow);
  const currentRule = currentRulesData?.mappedItems.get(ruleKey);
  const matchingRecommendation = recommendationsVerboseData?.mappedItems.get(ruleKey);
  const isUrgent = (matchingRecommendation?.usages_in_rules || 0) > 0;

  const toolTipContent = useMemo(() => {
    if (!currentRule || !matchingRecommendation) {
      return;
    }

    if (matchingRecommendation.recommended_action === 'remove') {
      return (
        <div className={styles.tooltipStyling}>
          {isUrgent && <div>{URGENT_RECOMMENDATION}</div>}
          <div>Click to remove this rule.</div>
        </div>
      );
    }

    const diff = ruleDiff(currentRule, matchingRecommendation);

    return (
      <div className={styles.tooltipStyling}>
        {isUrgent && <div>{URGENT_RECOMMENDATION}</div>}
        {diff.length && <div>The rule properties [{diff.join(', ')}] will be updated with this recommendation.</div>}
        <div>Click to go to the recommendation definition.</div>
      </div>
    );
  }, [currentRule, matchingRecommendation, styles.tooltipStyling, isUrgent]);

  const dataNotLoaded = !currentRulesData || !recommendationsVerboseData;

  if (
    dataNotLoaded ||
    !currentRule ||
    !matchingRecommendation ||
    !hasRecommendation(currentRule, matchingRecommendation)
  ) {
    return null;
  }

  const removeOnClick = async () => {
    const currentRules = currentRulesData.items;
    const updatedRules = removeRule(currentRules, ruleRowToRuleJson(ruleRow));

    await checkRulesAsync(updatedRules);
    await updateRulesAsync({ eTag: currentRulesData.eTag, rules: updatedRules });

    await setRuleSelection(ruleKey, false);

    setIsRemoveConfirmOpen(false);
  };

  const recommendationIcon = matchingRecommendation.recommended_action === 'update' ? 'arrow-up' : 'trash-alt';

  return (
    <span className={styles.icon} data-testid={'recommendation-indicator'}>
      <Button
        className={isUrgent ? styles.urgentButton : ''}
        icon={isUrgent ? 'exclamation-triangle' : recommendationIcon}
        size={'sm'}
        variant={'secondary'}
        tooltip={toolTipContent}
        onClick={() => {
          if (matchingRecommendation.recommended_action === 'remove') {
            setIsRemoveConfirmOpen(true);
          } else {
            goToRecommendation();
          }
        }}
      >
        {_capitalize(matchingRecommendation.recommended_action)}
      </Button>
      {matchingRecommendation.recommended_action === 'remove' && (
        <ConfirmModal
          isOpen={isRemoveConfirmOpen}
          title={'Remove rule?'}
          body={'This rule will be removed.'}
          confirmText={'Remove'}
          onConfirm={removeOnClick}
          onDismiss={() => setIsRemoveConfirmOpen(false)}
        />
      )}
    </span>
  );
};
