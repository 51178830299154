import React from 'react';

import { Field } from '@grafana/ui';

import { RuleRow } from '@/types';

export const SeriesReduction = ({
  ruleRow: {
    summary: { total_series_after_aggregation = 0, total_series_before_aggregation = 0 },
  },
}: {
  ruleRow: RuleRow;
}) => {
  // example 2000 - 1900
  const diff = total_series_before_aggregation - total_series_after_aggregation;
  const percentage = Math.abs(Math.floor((diff / total_series_before_aggregation) * 100));

  let changeVerb = diff < 0 ? 'increase' : 'decrease';

  return (
    <div>
      <Field label={'Change to time series'}>
        {diff === 0 ? (
          <>Your total time series for this metric will remain the same.</>
        ) : (
          <>{`Your total time series for this metric will ${changeVerb} by ${percentage}% (from ${total_series_before_aggregation} to ${total_series_after_aggregation})`}</>
        )}
      </Field>
    </div>
  );
};
