import React, { createContext, useEffect, useState } from 'react';

import { TimeRange } from '@grafana/data';

import { Exemption, RecommendedActionFilter } from '@/api/types';
import { PageType, RuleRow } from '@/types';
import { MINUTE_MS } from '@/util/constants';
import { getLookBackTimeRange } from '@/util/methods';

/**
 * Each field in PageFilters should evaluate to falsey if the filter is not applied.
 */
type PageFilters = {
  metricFilter: string;
  showOnlySelected: boolean;
  showOnlyWithRecommendations: boolean;
};

type PageDataType<T> = T extends 'configuration' ? Exemption[] : RuleRow[];

type AdaptiveMetricsContextType = {
  exemptionExpandSet: Set<string>;
  instance?: number;
  lookBackTimeRange: TimeRange;
  pageFilters: {
    [key in PageType]: PageFilters;
  };
  recommendedActionFilter: RecommendedActionFilter;
  selectedItems: { [key in PageType]: Set<Symbol> };
  setPageFilters: (pageFilters: { [key in PageType]: PageFilters }) => void;
  setRecommendedActionFilter: (recommendedActionFilter: RecommendedActionFilter) => void;
  setRuleSelection: (page: PageType, ruleKey: Symbol, isSelected: boolean) => void;
  setSelectedItems: (selectedItems: { [key in PageType]: Set<Symbol> }) => void;
  setTableData: (tableData: { [P in PageType]: PageDataType<P> }) => void;
  tableData: { [P in PageType]: PageDataType<P> };
  toggleExemptionExpand: (uid: string) => void;
  toggleSelectedItems: (page: PageType, itemKey: Symbol) => void;
};

export const AdaptiveMetricsContext = createContext<AdaptiveMetricsContextType>({} as AdaptiveMetricsContextType);

type Props = {
  children: React.ReactNode;
  instance?: number;
};
export const AdaptiveMetricsContextProvider = ({ children, instance }: Props) => {
  const [selectedItems, setSelectedItems] = useState<{ [key in PageType]: Set<Symbol> }>({
    configuration: new Set<Symbol>(),
    overview: new Set<Symbol>(),
    recommendations: new Set<Symbol>(),
    ruleManagement: new Set<Symbol>(),
    rules: new Set<Symbol>(),
  });

  const [pageFilters, setPageFilters] = useState<{ [key in PageType]: PageFilters }>({
    configuration: { metricFilter: '', showOnlySelected: false, showOnlyWithRecommendations: false },
    overview: { metricFilter: '', showOnlySelected: false, showOnlyWithRecommendations: false },
    recommendations: { metricFilter: '', showOnlySelected: false, showOnlyWithRecommendations: false },
    ruleManagement: { metricFilter: '', showOnlySelected: false, showOnlyWithRecommendations: false },
    rules: { metricFilter: '', showOnlySelected: false, showOnlyWithRecommendations: false },
  });

  const [tableData, setTableData] = useState<{ [P in PageType]: PageDataType<P> }>({
    configuration: [],
    overview: [],
    recommendations: [],
    ruleManagement: [],
    rules: [],
  });

  const [exemptionExpandSet, setExemptionExpandSet] = useState<Set<string>>(new Set<string>());

  const [lookBackTimeRange, setLookBackTimeRange] = useState<TimeRange>(getLookBackTimeRange());

  const [recommendedActionFilter, setRecommendedActionFilter] = useState<RecommendedActionFilter>('all');

  useEffect(() => {
    const interval = setInterval(() => {
      setLookBackTimeRange(getLookBackTimeRange());
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  const toggleSelectedItems = (page: PageType, ruleKey: Symbol) => {
    const pageSelectedRows = selectedItems[page];

    const isSelected = pageSelectedRows.has(ruleKey);

    if (isSelected) {
      pageSelectedRows.delete(ruleKey);
    } else {
      pageSelectedRows.add(ruleKey);
    }
    setSelectedItems({ ...selectedItems, [page]: new Set<Symbol>(pageSelectedRows) });
  };

  const setRuleSelection = (page: PageType, ruleKey: Symbol, isSelected: boolean) => {
    const pageSelectedRows = selectedItems[page];

    if (isSelected) {
      pageSelectedRows.add(ruleKey);
    } else {
      pageSelectedRows.delete(ruleKey);
    }
    setSelectedItems({ ...selectedItems, [page]: new Set<Symbol>(pageSelectedRows) });
  };

  const toggleExemptionExpand = (uid: string) => {
    if (exemptionExpandSet.has(uid)) {
      exemptionExpandSet.delete(uid);
    } else {
      exemptionExpandSet.add(uid);
    }

    setExemptionExpandSet(new Set<string>(exemptionExpandSet));
  };

  return (
    <AdaptiveMetricsContext.Provider
      value={{
        exemptionExpandSet,
        instance,
        lookBackTimeRange,
        pageFilters,
        recommendedActionFilter,
        selectedItems,
        setPageFilters,
        setRecommendedActionFilter,
        setRuleSelection,
        setSelectedItems,
        setTableData,
        tableData,
        toggleExemptionExpand,
        toggleSelectedItems,
      }}
    >
      {children}
    </AdaptiveMetricsContext.Provider>
  );
};
