import React, { useMemo } from 'react';

import { intersection as _intersection, union as _union, without as _without } from 'lodash';

import { Checkbox } from '@grafana/ui';

import { useCurrentPage, useSelectedItems, useTableData } from '@/hooks';
import { getExemptionKey, getRuleKey } from '@/util/methods';

export const TableHeaderCheckbox = () => {
  const page = useCurrentPage();
  const { selectedRulesAsArray, setSelectedItems } = useSelectedItems(page);
  const { tableData } = useTableData(page);

  const dataKeys = useMemo(() => {
    return tableData.map((item) => {
      if ('id' in item) {
        return getExemptionKey(item.id);
      } else {
        return getRuleKey(item);
      }
    });
  }, [tableData]);

  const selectAllState = useMemo(() => {
    const commonKeys = _intersection(dataKeys, selectedRulesAsArray);

    if (dataKeys.length && commonKeys.length === dataKeys.length) {
      return 'all';
    } else if (commonKeys.length > 0 || selectedRulesAsArray.length) {
      return 'partial';
    }
    return 'none';
  }, [dataKeys, selectedRulesAsArray]);

  const toggleSelectAllFn = (_: React.FormEvent<HTMLInputElement>) => {
    if (selectAllState === 'all') {
      // remove dataKeys from selectedRules
      setSelectedItems(new Set<Symbol>(_without(selectedRulesAsArray, ...dataKeys)));
      return;
    }
    setSelectedItems(new Set<Symbol>(_union(selectedRulesAsArray, dataKeys)));
  };

  return (
    <Checkbox
      indeterminate={selectAllState === 'partial'}
      onChange={toggleSelectAllFn}
      checked={selectAllState === 'all'}
    />
  );
};
