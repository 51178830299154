import React from 'react';

import { css } from '@emotion/css';
import { useBooleanFlagValue } from '@openfeature/react-sdk';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { MetricStats } from '@/components/RuleSummary/MetricStats';
import { RuleActions } from '@/components/RuleSummary/RuleActions';
import { RuleDisplay } from '@/components/RuleSummary/RuleDisplay';
import { RuleRow } from '@/types';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    actions: css({
      alignSelf: 'flex-end',
    }),
    container: css({
      display: 'flex',
      div: {
        flex: 1,
        minWidth: theme.spacing(30),
      },
      flexFlow: 'row wrap',
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    }),
  };
};

type Props = {
  ruleRow: RuleRow;
};
export const RuleSummary = ({ ruleRow }: Props) => {
  const styles = useStyles2(getStyles);
  const combinedRulesPage = useBooleanFlagValue('combine_rules_page', false);

  return (
    <div data-testid={'rule-summary'} className={styles.container}>
      <RuleDisplay ruleRow={ruleRow} />
      <MetricStats ruleRow={ruleRow} />
      {!combinedRulesPage && <RuleActions ruleRow={ruleRow} />}
    </div>
  );
};
