import React from 'react';

import { AppRootProps } from '@grafana/data';
import { Alert } from '@grafana/ui';

import { GrafanaVersionGrid } from '@/components/GrafanaVersionGrid';
import PageWrapper from '@/components/PageWrapper';
import { AppPluginSettings } from '@/pages/AppConfig';

export const UnsupportedVersion = (props: AppRootProps<AppPluginSettings>) => {
  return (
    <PageWrapper logo={props.meta.info.logos.large}>
      <Alert title="Minimum Grafana version not installed" severity="error">
        To view Adaptive Metrics, install a newer version of Grafana.
        <GrafanaVersionGrid />
      </Alert>
    </PageWrapper>
  );
};
