import React from 'react';

import { Checkbox } from '@grafana/ui';

import { useCurrentPage, useSelectedItems } from '@/hooks';
import { RuleRow } from '@/types';
import { getExemptionKey, getRuleKey } from '@/util/methods';

type Props = {
  item: RuleRow | string;
};
export const ItemCheckbox = ({ item }: Props) => {
  const page = useCurrentPage();
  const { selectedItems, toggleSelectedItems } = useSelectedItems(page);

  let key: Symbol;

  if (typeof item === 'string') {
    key = getExemptionKey(item);
  } else {
    key = getRuleKey(item);
  }

  const isItemSelected = selectedItems.has(key);

  const onChange = (_: React.FormEvent<HTMLInputElement>) => {
    toggleSelectedItems(key);
  };

  return (
    <span data-testid={'item-checkbox'}>
      <Checkbox onChange={onChange} checked={isItemSelected} />
    </span>
  );
};
