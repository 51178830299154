import React, { useRef, useState } from 'react';

import { css } from '@emotion/css';
import { useResizeObserver } from '@react-aria/utils';

import { GrafanaTheme2, LoadingState } from '@grafana/data';
import { PanelRenderer } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';

import { useLookBackTimeRange, useRawAggDiffSeries } from '@/hooks';
import { getTimeseriesPanelData } from '@/util/time-series';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css({
      width: '100%',
    }),
  };
};

export const RawVsAggregated = () => {
  const styles = useStyles2(getStyles);
  const lookBackTimeRange = useLookBackTimeRange();
  const { data: rawAggDiffSeriesData } = useRawAggDiffSeries();

  const panelRendererRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useResizeObserver({
    onResize: () => {
      const element = panelRendererRef.current;

      if (element) {
        setWidth(element.clientWidth);
      }
    },
    ref: panelRendererRef,
  });

  if (rawAggDiffSeriesData?.data) {
    const { data: rawAggDiffSeries } = rawAggDiffSeriesData;
    return (
      <div className={styles.container} ref={panelRendererRef}>
        <h4>Time series reduced by applied rules</h4>
        <PanelRenderer
          title={'Time series reduced by applied rules'}
          pluginId={'timeseries'}
          width={width}
          height={300}
          options={{}}
          data={getTimeseriesPanelData([...rawAggDiffSeries.data.result], lookBackTimeRange)}
        />
      </div>
    );
  }

  return (
    <div className={styles.container} ref={panelRendererRef}>
      <h4>Raw vs Aggregated</h4>
      <PanelRenderer
        title={'Time series reduced by applied rules'}
        pluginId={'timeseries'}
        width={width}
        height={300}
        options={{}}
        data={{
          series: [],
          state: LoadingState.Done,
          timeRange: lookBackTimeRange,
        }}
      />
    </div>
  );
};
