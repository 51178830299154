import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, useStyles2 } from '@grafana/ui';

import { LabelDisplay } from '@/components/LabelDisplay';

interface Props {
  labels?: string[];
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    allLabels: css({
      color: theme.colors.text.secondary,
      fontStyle: 'italic',
    }),
  };
};

export const KeepLabels = ({ labels }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <div>
      {labels?.length ? (
        <LabelDisplay labels={labels} title="Keep labels" />
      ) : (
        <Field label="Keep Labels">
          <span className={styles.allLabels}>All labels</span>
        </Field>
      )}
    </div>
  );
};
