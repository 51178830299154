import React from 'react';

import { css } from '@emotion/css';
import { capitalize as _capitalize, isEqual as _isEqual } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, TagList, useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    labelDisplay: {
      container: css({
        display: 'flex',
        gap: theme.spacing(1),
      }),
      tagList: css({
        justifyContent: 'flex-start',
        li: {
          span: {
            backgroundColor: theme.colors.background.secondary,
            border: `1px solid ${theme.colors.border.medium}`,
            color: theme.colors.text.primary,
          },
        },
      }),
    },
    tagListContainer: css({
      flex: 0,
      width: 'fit-content',
    }),
  };
};

export const LabelDisplay = ({
  description = '',
  existingLabels,
  labels,
  title,
}: {
  description?: string;
  existingLabels?: string[];
  labels: string[];
  title: string;
}) => {
  const styles = useStyles2(getStyles);

  if (existingLabels?.length && !_isEqual(existingLabels, labels)) {
    return (
      <div>
        <Field
          className={styles.tagListContainer}
          label={_capitalize(`Existing rule ${title}`)}
          description={description}
        >
          <TagList className={styles.labelDisplay.tagList} tags={existingLabels} />
        </Field>
        <Field className={styles.tagListContainer} label={_capitalize(`New rule ${title}`)} description={description}>
          <TagList className={styles.labelDisplay.tagList} tags={labels} />
        </Field>
      </div>
    );
  }

  return (
    <>
      <Field className={styles.tagListContainer} label={title} description={description}>
        <TagList className={styles.labelDisplay.tagList} tags={labels} />
      </Field>
    </>
  );
};
