import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { ApplyRecommendation } from '@/components/RuleActions/ApplyRecommendation';
import { CopyJson } from '@/components/RuleActions/CopyJson';
import { EditRule } from '@/components/RuleActions/EditRule';
import { useRecommendations, useRules } from '@/hooks';
import { RuleRow } from '@/types';
import { getRuleKey } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => ({
  actionsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  }),
  tooltipStyling: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
  urgentButton: css({
    '&:hover': {
      backgroundColor: theme.colors.warning.transparent,
      border: `1px solid ${theme.colors.emphasize(theme.colors.warning.border, 0.25)}`,
    },
    backgroundColor: theme.colors.warning.transparent,
    border: `1px solid ${theme.colors.warning.border}`,
    color: theme.colors.getContrastText(theme.colors.warning.transparent),
  }),
});

type Props = {
  ruleRow: RuleRow;
};

export const RuleActions = ({ ruleRow }: Props) => {
  const styles = useStyles2(getStyles);
  const { data: currentRulesData } = useRules();

  // we use the verbose recommendations here because they will include 'remove' type actions
  const { data: recommendationsVerboseData } = useRecommendations(true);

  if (!currentRulesData || !recommendationsVerboseData) {
    return null;
  }

  const ruleKey: Symbol = getRuleKey(ruleRow);
  const existingRule = currentRulesData.mappedItems.get(ruleKey);

  return (
    <span className={styles.actionsContainer}>
      {/* Recommended action button */}
      <ApplyRecommendation ruleRow={ruleRow} />
      {/* Edit the existing rule */}
      {existingRule && <EditRule ruleRow={ruleRow} />}
      {/* Copy JSON */}
      <CopyJson ruleRow={ruleRow} />
    </span>
  );
};
