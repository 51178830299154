import React, { useEffect } from 'react';

import { OpenFeatureProvider } from '@openfeature/react-sdk';
import { OpenFeature } from '@openfeature/web-sdk';
import { QueryClientProvider } from '@tanstack/react-query';
import { snakeCase as _snakeCase } from 'lodash';

import { AppRootProps } from '@grafana/data';
import { config, getBackendSrv } from '@grafana/runtime';

import { updateFlagsmithIdentity } from '@/api';
import { GcomInstance } from '@/api/types';
import { AdaptiveMetricsContextProvider } from '@/context/adaptive-metrics-context';
import { AppPluginSettings } from '@/pages/AppConfig';
import { paths } from '@/util/constants';
import { flagsmithProvider, queryClient } from '@/util/state';

type Props = {
  children: React.ReactNode;
  props: AppRootProps<AppPluginSettings>;
};
export const ContextProviders = ({ children, props }: Props) => {
  const { jsonData } = props.meta;

  const identifier = _snakeCase(config.appUrl);

  useEffect(() => {
    getBackendSrv()
      .get<GcomInstance>(paths.gcomInstances)
      .then(async (response) => {
        const traits = {
          app_url: config.appUrl,
          cluster_id: response.clusterId,
          cluster_slug: response.clusterSlug,
          grafana_version: response.version,
          license_edition: config.licenseInfo.edition,
          namespace: config.namespace,
          org_id: response.orgId,
          org_slug: response.orgSlug,
          plan: response.plan,
          plan_name: response.planName,
          region_id: response.regionId,
          region_slug: response.regionSlug,
          slug: response.slug,
          stack_id: response.id,
        };

        // we don't want to wait on this call since it's not hitting the edge-proxy.
        updateFlagsmithIdentity(identifier, traits);

        OpenFeature.setProvider(flagsmithProvider(), {
          targetingKey: identifier,
          traits,
        });
      })
      .catch(() => {
        // if the request to get instance data fails, only pass traits that we have from bootdata
        OpenFeature.setProvider(flagsmithProvider(), {
          targetingKey: identifier,
          traits: {
            app_url: config.appUrl,
            license_edition: config.licenseInfo.edition,
            namespace: config.namespace,
          },
        });
      });
  }, [identifier]);

  return (
    <OpenFeatureProvider suspend={true}>
      <AdaptiveMetricsContextProvider instance={jsonData?.instance}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </AdaptiveMetricsContextProvider>
    </OpenFeatureProvider>
  );
};
