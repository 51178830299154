import React from 'react';

import { Usages } from '@/components/RuleSummary/Usages';
import { RuleRow } from '@/types';

type Props = {
  ruleRow: RuleRow;
};
export const MetricStats = ({ ruleRow }: Props) => {
  return (
    <div>
      <h2>Metric usage stats</h2>
      <hr />
      <Usages ruleRow={ruleRow} />
    </div>
  );
};
