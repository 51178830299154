import React from 'react';

import { Icon } from '@grafana/ui';

type ExternalLinkProperties = {
  children: string;
  href: string;
};

export function ExternalLink({ children, href }: ExternalLinkProperties) {
  return (
    <a className={'external-link'} target="_blank" href={href} rel="noreferrer">
      <span style={{ whiteSpace: 'nowrap' }}>
        {children} <Icon size="sm" name="external-link-alt" />
      </span>
    </a>
  );
}
