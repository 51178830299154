import { cloneDeep as _cloneDeep, isUndefined as _isUndefined } from 'lodash';

import { AggregationRule, Recommendation } from '@/api/types';
import { ConfirmationData, TableRow } from '@/components/ConfirmationModal/types';

export const confirmationDataToTableRows = (data: ConfirmationData) => {
  const td: TableRow[] = [];

  Object.entries(data).forEach(([action, { ruleKeys, seriesAfter, seriesBefore }]) => {
    let seriesEffect: number | undefined = 0;
    if (ruleKeys.size) {
      seriesEffect = !_isUndefined(seriesBefore) && !_isUndefined(seriesAfter) ? seriesAfter - seriesBefore : undefined;
    }

    if (!['keep', 'unknown'].includes(action)) {
      td.push({ action: action as Recommendation, quantity: ruleKeys.size, seriesEffect });
    }
  });
  return td;
};

/**
 * Processes the confirmation data and applies changes to the ruleMap passed in
 * @param data
 * @param originalMap
 * @param recommendations
 */
export const applyConfirmationData = (
  data: ConfirmationData,
  originalMap: Map<Symbol, AggregationRule>,
  recommendations: Map<Symbol, AggregationRule>,
  selectedActions: Set<string>
): Map<Symbol, AggregationRule> => {
  const ruleMap: Map<Symbol, AggregationRule> = _cloneDeep(originalMap);

  Object.entries(data).forEach(([action, { ruleKeys }]) => {
    if (!selectedActions.has(action)) {
      return;
    }
    if (action === 'remove') {
      ruleKeys.forEach((ruleKey) => {
        ruleMap.delete(ruleKey);
      });
    } else if (!['keep', 'unknown'].includes(action)) {
      ruleKeys.forEach((ruleKey) => {
        const recommendedRule = recommendations.get(ruleKey);
        if (recommendedRule) {
          ruleMap.set(ruleKey, recommendedRule);
        }
      });
    }
  });
  return ruleMap;
};
