import React, { useState } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { ConfirmModal, IconButton, useStyles2 } from '@grafana/ui';

import { ConfirmModalLoader } from '../ConfirmModalLoader';
import { ExemptionBuilderModal } from '../ExemptionBuilderModal';
import { Exemption } from '@/api/types';
import { QUERY_KEYS, useDeleteExemptionMutation, useExemptions, useUpdateExemptionsMutation } from '@/hooks';
import { queryClient } from '@/util/state';

interface Props {
  exemption: Exemption;
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    actionColumn: css({
      display: 'flex',
      gap: theme.spacing(1),
    }),
  };
};

export const ExemptionActionButtons = ({ exemption }: Props) => {
  const styles = useStyles2(getStyles);
  const [isOpen, setIsOpen] = useState(false);
  const [editExemptionModalIsOpen, setEditExemptionModalIsOpen] = useState(false);
  const { isLoading: isExemptionDeleting, mutateAsync: deleteExemptionAsync } = useDeleteExemptionMutation();
  const { isLoading: isExemptionUpdating, mutateAsync: updateExemptionAsync } = useUpdateExemptionsMutation();
  const { isFetching: isFetchingExemption } = useExemptions();

  const isDeleting = isExemptionDeleting || isFetchingExemption;

  return (
    <div className={styles.actionColumn}>
      <IconButton
        aria-label={'Edit Exemption'}
        data-testid="edit-icon-button"
        name="pen"
        onClick={() => setEditExemptionModalIsOpen(true)}
      />
      <ExemptionBuilderModal
        isOpen={editExemptionModalIsOpen}
        onConfirm={updateExemptionAsync}
        onDismiss={() => setEditExemptionModalIsOpen(false)}
        exemption={exemption}
        isLoading={isExemptionUpdating || isFetchingExemption}
      />
      <IconButton
        aria-label={'Delete Exemption'}
        data-testid="delete-icon-button"
        name="trash-alt"
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <ConfirmModal
        isOpen={isOpen}
        title="Delete Exemption?"
        body={<div>Exemption will be removed</div>}
        confirmText={isDeleting ? ((<ConfirmModalLoader text="Deleting..." />) as unknown as string) : 'Delete'}
        confirmButtonVariant="destructive"
        onConfirm={async () => {
          await deleteExemptionAsync(exemption.id);
          await queryClient.invalidateQueries([QUERY_KEYS.exemptions]);
          await setIsOpen(false);
        }}
        onDismiss={() => setIsOpen(false)}
      />
    </div>
  );
};
