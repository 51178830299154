import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, useStyles2 } from '@grafana/ui';

import { LabelDisplay } from '@/components/LabelDisplay';

interface Props {
  metric?: string;
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    allMetrics: css({
      color: theme.colors.text.secondary,
      fontStyle: 'italic',
    }),
  };
};

export const MetricName = ({ metric }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <div data-testid="exemption-metric-detail">
      {metric ? (
        <LabelDisplay labels={[metric]} title="Metric" />
      ) : (
        <Field label="Metric">
          <span className={styles.allMetrics}>All metrics</span>
        </Field>
      )}
    </div>
  );
};
