import { useCallback, useEffect, useState } from 'react';

import copy from 'copy-to-clipboard';

export const useCopyToClipboard = (text: string, duration = 2500): [boolean, () => void] => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const id = setTimeout(() => {
        setIsCopied(false);
      }, duration);
      return () => {
        clearTimeout(id);
      };
    }
    return () => void 0;
  }, [isCopied, duration]);

  return [
    isCopied,
    useCallback(() => {
      const didCopy = copy(text, { debug: true });
      setIsCopied(didCopy);
    }, [setIsCopied, text]),
  ];
};
