import React from 'react';

import { css } from '@emotion/css';
import { isUndefined as _isUndefined } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { RuleRow } from '@/types';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    usageList: css({
      dd: {
        margin: 0,
      },
      display: 'grid',
      gridColumnGap: theme.spacing(2),
      gridTemplateColumns: '1fr 1fr',
      width: 'fit-content',
    }),
  };
};

export const Usages = ({
  ruleRow: {
    summary: { usages_in_dashboards, usages_in_queries, usages_in_rules },
  },
}: {
  ruleRow: RuleRow;
}) => {
  const styles = useStyles2(getStyles);

  return (
    <div>
      <dl className={styles.usageList}>
        <dt>Alerting and recording rules</dt>
        <dd>{_isUndefined(usages_in_rules) ? <i>Unknown</i> : usages_in_rules}</dd>
        <dt>Dashboards</dt>
        <dd>{_isUndefined(usages_in_dashboards) ? <i>Unknown</i> : usages_in_dashboards}</dd>
        <dt>Queries</dt>
        <dd>{_isUndefined(usages_in_queries) ? <i>Unknown</i> : usages_in_queries}</dd>
      </dl>
    </div>
  );
};
