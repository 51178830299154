import React from 'react';

import { Card, Field, Spinner } from '@grafana/ui';

import { ExternalLink } from '@/components/ExternalLink';
import { useRecommendationsConfig } from '@/hooks';

export function AggregationsConfig() {
  const { data, isLoading } = useRecommendationsConfig();

  return (
    <Card>
      <Card.Heading>Recommendations configuration</Card.Heading>
      <Card.Description>
        {isLoading && <Spinner />}
        {data && (
          <>
            <Field label="Last modified">
              <span>{data.lastModified ? data.lastModified : 'N/A'}</span>
            </Field>
            <Field label="Keep labels">
              <span>
                {data.items[0].keep_labels?.length ? (
                  <>
                    {data.items[0].keep_labels.map((label: string) => (
                      <pre key={label}>label</pre>
                    ))}
                  </>
                ) : (
                  'N/A'
                )}
              </span>
            </Field>
          </>
        )}
        <ExternalLink href="https://grafana.com/docs/grafana-cloud/data-configuration/metrics/manage-metrics-costs-via-adaptive-metrics/#list-current-recommendations-configuration">
          Documentation
        </ExternalLink>
      </Card.Description>
    </Card>
  );
}
