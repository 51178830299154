import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { includes as _includes, pick as _pick, sortBy as _sortBy } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { Column, Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { NoRules } from './NoRules';
import { AggregationRule } from '@/api/types';
import { ItemCheckbox } from '@/components/ItemCheckbox';
import { MetricCell } from '@/components/MetricCell';
import { PageHeader } from '@/components/PageHeader';
import { QueryResultHeader } from '@/components/QueryResultHeader';
import { hasRecommendation, RecommendationIndicator } from '@/components/RecommendationIndicator';
import { RuleSummary } from '@/components/RuleSummary';
import { TableHeaderCheckbox } from '@/components/TableHeaderCheckbox';
import { TableWithCheckbox } from '@/components/TableWithCheckbox';
import { useCurrentPage, usePageFilters, useRecommendations, useRules } from '@/hooks';
import { RuleRow } from '@/types';
import { convertToRuleRow, getRuleKey } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    icon: css({
      marginLeft: theme.spacing(0.5),
      verticalAlign: 'text-top',
    }),
    tableContainer: css({
      display: 'flex',
      flexDirection: 'column',
    }),
  };
};

type Styles = ReturnType<typeof getStyles>;

const getColumns = (styles: Styles): Array<Column<RuleRow>> => {
  return [
    {
      cell: ({ row: { original: ruleRow } }) => {
        return <ItemCheckbox item={ruleRow} />;
      },
      disableGrow: true,
      header: (<TableHeaderCheckbox />) as unknown as string,
      id: 'rule-selector',
    } as Column<RuleRow>,
    {
      cell: ({ row }) => {
        const ruleRow: RuleRow = row.original;
        return <MetricCell row={ruleRow} />;
      },
      header: 'Metric',
      id: 'metric',
    } as Column<RuleRow>,
    {
      header: 'Rule type',
      id: 'ruleType',
    } as Column<RuleRow>,
    {
      cell: ({ row }) => {
        const ruleRow: RuleRow = row.original;
        return <RecommendationIndicator ruleRow={ruleRow} />;
      },
      disableGrow: true,
      header: (
        <>
          Recommendation
          <Tooltip content={'A recommendation has been generated for a currently applied rule'} placement={'top'}>
            <Icon className={styles.icon} name={'info-circle'} />
          </Tooltip>
        </>
      ) as unknown as string,
      id: 'recommendation-indicator',
    } as Column<RuleRow>,
  ];
};

export const CurrentRules = () => {
  const styles = useStyles2(getStyles);
  const page = useCurrentPage();
  const { showOnlyWithRecommendations } = usePageFilters(page);
  const {
    data: currentRulesData,
    error: currentRulesError,
    isError: currentRulesIsError,
    isLoading: currentRulesIsLoading,
  } = useRules();

  const {
    data: recommendationsData,
    error: recommendationsError,
    isError: recommendationsIsError,
    isLoading: recommendationsIsLoading,
  } = useRecommendations(true);

  const columns = useMemo(() => getColumns(styles), [styles]);

  const mergedRuleData: AggregationRule[] = useMemo(() => {
    if (currentRulesData?.mappedItems && currentRulesData?.mappedItems.size === 0) {
      return [];
    }

    const res: AggregationRule[] = [];
    currentRulesData?.mappedItems.forEach((currentRule) => {
      const recommendation = recommendationsData?.mappedItems.get(getRuleKey(currentRule)) || {};
      const recommendationUniqueKeys = Object.keys(recommendation).filter(
        (key) => !_includes(Object.keys(currentRule), key)
      );

      res.push({ ...currentRule, ..._pick(recommendation, recommendationUniqueKeys) });
    });
    return res;
  }, [currentRulesData, recommendationsData]);

  const tableData = useMemo(() => {
    if (mergedRuleData.length === 0) {
      return [];
    }

    return _sortBy(
      mergedRuleData
        // we leave only with recommendations filter here because it's rules page specific
        .filter((rule) => {
          const ruleKey = getRuleKey(rule);
          const currentRule = currentRulesData?.mappedItems.get(ruleKey);
          const recommendation = recommendationsData?.mappedItems.get(ruleKey);
          return !showOnlyWithRecommendations ? true : hasRecommendation(currentRule, recommendation);
        })
        .map((rule): RuleRow => {
          return convertToRuleRow(rule);
        }),
      (value) => -(value.summary.usages_in_rules || 0)
    );
  }, [currentRulesData, mergedRuleData, recommendationsData, showOnlyWithRecommendations]);

  if (!currentRulesData?.mappedItems) {
    return (
      <QueryResultHeader
        errors={[currentRulesError, recommendationsError]}
        isErrorArr={[currentRulesIsError, recommendationsIsError]}
        isLoadingArr={[currentRulesIsLoading, recommendationsIsLoading]}
      />
    );
  }

  const renderSubComponent = (ruleRow: RuleRow) => {
    reportInteraction('g_adaptive_metrics_app_metric_rule_expanded', { metric: ruleRow.metric, page });
    return <RuleSummary ruleRow={ruleRow} />;
  };

  if (currentRulesData?.mappedItems.size === 0) {
    return <NoRules />;
  }

  return (
    <div>
      <QueryResultHeader
        errors={[currentRulesError, recommendationsError]}
        isErrorArr={[currentRulesIsError, recommendationsIsError]}
        isLoadingArr={[currentRulesIsLoading, recommendationsIsLoading]}
      />
      <PageHeader />
      <div className={styles.tableContainer}>
        <TableWithCheckbox columns={columns} data={tableData} subComponent={renderSubComponent} />
      </div>
    </div>
  );
};
