import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { Button, useStyles2 } from '@grafana/ui';

import { AggregationRule } from '@/api/types';
import { CopyToClipboardButton } from '@/components/CopyToClipboardButton';
import {
  useCheckRulesMutation,
  useCurrentPage,
  useRules,
  useSelectedItems,
  useUpdateRulesMutation,
  useUserPermissions,
} from '@/hooks';
import { RuleRow } from '@/types';
import { addOrUpdateRule, getRuleKey, removeRule, ruleRowToRuleJson, ruleRowToRuleJsonString } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    actions: css({
      alignSelf: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      width: 'fit-content',
    }),
    fitButtonContent: css({
      alignSelf: 'flex-end',
    }),
  };
};

type Props = {
  ruleRow: RuleRow;
};
export const RuleActions = ({ ruleRow }: Props) => {
  const styles = useStyles2(getStyles);
  const page = useCurrentPage();
  const userPermissions = useUserPermissions();

  const { data: currentRulesData } = useRules();
  const { setRuleSelection } = useSelectedItems(page);

  const { isLoading: checkRulesIsLoading, mutateAsync: checkRulesAsync } = useCheckRulesMutation();
  const { isLoading: updateRulesIsLoading, mutateAsync: updateRulesAsync } = useUpdateRulesMutation();

  const action = ruleRow.recommended_action === 'add' || ruleRow.recommended_action === 'update' ? 'add' : 'remove';

  const buttonVariant = action === 'add' ? 'primary' : 'destructive';
  const buttonText = action === 'add' ? 'Apply recommendation' : 'Remove rule';

  if (!currentRulesData) {
    return null;
  }

  const handleClick = async () => {
    const existingRules = Array.from(currentRulesData.mappedItems.values() || []);
    const rule = ruleRowToRuleJson(ruleRow);

    let updatedRules: AggregationRule[];

    if (action === 'remove') {
      updatedRules = removeRule(existingRules, rule);
    } else {
      updatedRules = addOrUpdateRule(existingRules, rule);
    }

    reportInteraction('g_adaptive_metrics_app_rule_apply', {
      action: action === 'add' ? ruleRow.recommended_action : 'remove',
      metric: ruleRow.metric,
      page,
    });

    await checkRulesAsync(updatedRules);
    await updateRulesAsync({ eTag: currentRulesData.eTag, rules: updatedRules });
    await setRuleSelection(getRuleKey(rule), false);
  };

  const busy = checkRulesIsLoading || updateRulesIsLoading;

  return (
    <span className={styles.actions}>
      <CopyToClipboardButton
        className={styles.fitButtonContent}
        metricName={ruleRow.metric}
        fill={'solid'}
        buttonVariant={'secondary'}
        textToCopy={ruleRowToRuleJsonString(ruleRow)}
      />
      {((page === 'rules' && userPermissions.canDeleteRules) ||
        (page === 'recommendations' && userPermissions.canApplyRecommendations)) && (
        <Button data-testid={'rule-action-primary'} variant={buttonVariant} onClick={handleClick} disabled={busy}>
          {buttonText}
        </Button>
      )}
    </span>
  );
};
