import { Configuration } from '@/pages/Configuration';
import { CurrentRules } from '@/pages/CurrentRules';
import { Overview } from '@/pages/Overview';
import { Recommendations } from '@/pages/Recommendations';
import { RuleManagement } from '@/pages/RuleManagement';
import { PageDefinition, PageType } from '@/types';

export const pages = new Map<PageType, PageDefinition>([
  [
    'configuration',
    {
      component: Configuration,
      id: 'configuration',
      reqAction: 'grafana-adaptive-metrics-app.exemptions:read',
      text: 'Configuration',
    },
  ],
  [
    'overview',
    {
      component: Overview,
      id: 'overview',
      reqAction: 'grafana-adaptive-metrics-app.plugin:access',
      text: 'Overview',
    },
  ],
  [
    'recommendations',
    {
      component: Recommendations,
      id: 'recommendations',
      reqAction: 'grafana-adaptive-metrics-app.recommendations:read',
      text: 'Recommended rules',
    },
  ],
  [
    'rules',
    {
      component: CurrentRules,
      id: 'rules',
      reqAction: 'grafana-adaptive-metrics-app.rules:read',
      text: 'Applied rules',
    },
  ],
  [
    'ruleManagement',
    {
      component: RuleManagement,
      id: 'ruleManagement',
      reqAction: 'grafana-adaptive-metrics-app.recommendations:read',
      text: 'Rules',
    },
  ],
]);
