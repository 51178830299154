import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { config } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';

import { isGrafanaVersionSupported, MINIMUM_GRAFANA_VERSION, RECOMMENDED_GRAFANA_VERSION } from '@/util/grafana';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    versionGrid: css({
      dd: {
        marginBottom: 0,
      },
      display: 'grid',
      gap: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      gridTemplateColumns: 'auto 1fr',
      paddingTop: theme.spacing(1),
      width: 'fit-content',
    }),
  };
};

export const GrafanaVersionGrid = () => {
  const styles = useStyles2(getStyles);
  return (
    <dl className={styles.versionGrid}>
      <dt>Installed version</dt>
      <dd>
        <code>{config.buildInfo.version}</code>
      </dd>
      {!isGrafanaVersionSupported() && (
        <>
          <dt>Minimum supported version</dt>
          <dd>
            <code>{MINIMUM_GRAFANA_VERSION}</code>
          </dd>
        </>
      )}
      <dt>Recommended version</dt>
      <dd>
        <code>{'>=' + RECOMMENDED_GRAFANA_VERSION}</code>
      </dd>
    </dl>
  );
};
