import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { ExemptionDetails } from './ExemptionDetails';
import { KeepLabels } from './KeepLabels';
import { MetricName } from './MetricName';
import { Exemption } from '@/api/types';

interface Props {
  exemption: Exemption;
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css({
      display: 'flex',
      div: {
        flex: 1,
        minWidth: theme.spacing(30),
      },
      flexFlow: 'row wrap',
      gap: theme.spacing(2),
      padding: `0 ${theme.spacing(2)}`,
    }),
  };
};

export const ExemptionSummary = ({ exemption }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      <MetricName metric={exemption.metric} />
      {!exemption.disable_recommendations ? (
        <KeepLabels labels={exemption.keep_labels} />
      ) : (
        <div>All recommendations are disabled for this metric, and existing rule state is preserved.</div>
      )}
      <ExemptionDetails exemption={exemption} />
    </div>
  );
};
