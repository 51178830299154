import { MatchType } from '@/api/types';
import { PageFeatures, PageType } from '@/types';

export const PAGE_SIZE = 15;
export const MINUTE_MS = 60 * 1000;

export const PAGE_FEATURES: Record<PageType, PageFeatures> = {
  configuration: {
    // TODO #206: confirmation of need of download function
    downloadData: false,
  },
  overview: {
    downloadData: false,
  },
  recommendations: {
    downloadData: true,
  },
  ruleManagement: {
    downloadData: true,
  },
  rules: {
    downloadData: false,
  },
};

export const MATCH_TYPE_DESCRIPTION: Record<MatchType, string> = {
  exact: '',
  prefix: 'With prefix',
  suffix: 'With suffix',
};

const pluginId = 'grafana-adaptive-metrics-app';
export const USAGE_DATASOURCE = 'grafanacloud-usage';
export const GRAFANA_PROM_DATASOURCE = 'grafanacloud-prom';
export const proxyBasePath = `/api/plugin-proxy/${pluginId}`;
const cloudUsageProxyPath = `/api/datasources/proxy/uid/${USAGE_DATASOURCE}`;
const grafanaPromPath = `/api/datasources/uid/${GRAFANA_PROM_DATASOURCE}/resources`;
const grafanaPromMetricEndpoint = `/api/v1/label/__name__/values`;
const grafanaPromLabelEndpoint = `/api/v1/labels`;

export const paths = {
  appRecommendations: `/a/${pluginId}/recommendations`,
  appRoot: `/a/${pluginId}`,
  checkRules: `${proxyBasePath}/check-rules`,
  cloudUsageQuery: `${cloudUsageProxyPath}/api/v1/query`,
  cloudUsageQueryRange: `${cloudUsageProxyPath}/api/v1/query_range`,
  exemptions: `${proxyBasePath}/exemptions`,
  // trailing slash required on flagsApi
  flagsApi: `${proxyBasePath}/flagsApi/`,
  flagsIdentityUpdate: `${proxyBasePath}/flagsApi/identities/update`,
  gcomInstances: `${proxyBasePath}/gcom/instances`,
  getLabelValuesPath: (label: string) => `${grafanaPromPath}/api/v1/label/${label}/values`,
  grafanaPromLabels: `${grafanaPromPath}${grafanaPromLabelEndpoint}`,
  grafanaPromMetrics: `${grafanaPromPath}${grafanaPromMetricEndpoint}`,
  pluginPublic: `/public/plugins/${pluginId}`,
  recommendations: `${proxyBasePath}/recommendations`,
  recommendationsConfig: `${proxyBasePath}/recommendations/config`,
  rules: `${proxyBasePath}/rules`,
  segments: `${proxyBasePath}/segments`,
} as const;

export const promQueries = {
  aggregatedSeries: 'grafanacloud_instance_aggregation_aggregated_series',
  estimatedSavings: 'grafanacloud_instance_recommendations_estimated_savings_series',
  rawSeries: 'grafanacloud_instance_aggregation_raw_series',
  totalActiveSeries: 'grafanacloud_instance_active_series',
};

export const RECOMMENDATIONS_OUT_OF_SYNC =
  'The recommended rules were generated for a previous version of applied rules.';

export const DIVISIONS = 5;
export const LARGE_SPAN = Math.floor(2 * (DIVISIONS / 3));

export const URGENT_RECOMMENDATION =
  'The currently applied rule is used in alerting or recording rules. To prevent an incident due to delays in alerts, it is strongly recommended to apply this recommendation.';

export const FLAGSMITH_DEV_CLIENT_KEY = '9y8TsLTop5gYC5kG5LeDHc';
export const FLAGSMITH_PROD_CLIENT_KEY = 'HDR7VthogWMU8bj8s4hnsz';
