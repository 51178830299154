import React, { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { debounce as _debounce } from 'lodash';

import { SelectableValue } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { Button, Field, Icon, Input, RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { RecommendedActionFilter } from '@/api/types';
import { AdvancedFilter } from '@/components/AdvancedFilter';
import { useCurrentPage, usePageFilters, useRecommendedActionFilter } from '@/hooks';

const recommendationTypes: Array<SelectableValue<RecommendedActionFilter>> = [
  { label: 'All', value: 'all' },
  { label: 'Add', value: 'add' },
  { label: 'Keep', value: 'keep' },
  { label: 'Remove', value: 'remove' },
  { label: 'Update', value: 'update' },
];

const getStyles = () => {
  return {
    dropdownContainer: css({
      display: 'flex',
      position: 'relative',
    }),
  };
};

export const FilterField = ({ className, placeholder }: { className?: string; placeholder?: string }) => {
  const page = useCurrentPage();
  const { metricFilter, setMetricFilter } = usePageFilters(page);
  const styles = useStyles2(getStyles);

  const [isOpen, setIsOpen] = useState(false);
  const filterUIenabled = useBooleanFlagValue('filter_ui', false);
  const { recommendedActionFilter, setRecommendedActionFilter } = useRecommendedActionFilter();

  const debouncedReportInteraction = useMemo(
    () =>
      _debounce((s: string) => {
        reportInteraction('g_adaptive_metrics_app_filter_rules', { filter: s || '__none__', page });
      }, 500),
    [page]
  );

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const filterString = event.currentTarget.value;
    setMetricFilter(filterString);
    debouncedReportInteraction(filterString);
  };

  return (
    <>
      {filterUIenabled ? (
        <Field
          className={cx(
            css({
              marginBottom: 0,
            }),
            className
          )}
          data-testid={'filter-field'}
          label="Filter"
        >
          <div className={styles.dropdownContainer}>
            <Button
              data-testid="advanced-filter-button"
              variant="secondary"
              icon="filter"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
            {isOpen && <AdvancedFilter setIsOpen={setIsOpen} />}

            <Input placeholder={placeholder || 'Metric name'} value={metricFilter} onChange={onChange} />
          </div>
        </Field>
      ) : (
        <>
          <Field
            className={cx(
              css({
                marginBottom: 0,
              }),
              className
            )}
            data-testid={'filter-field'}
            label="Filter"
          >
            <Input
              prefix={<Icon name="filter" />}
              placeholder={placeholder || 'Metric name'}
              value={metricFilter}
              onChange={onChange}
            />
          </Field>
          {page !== 'rules' && (
            <Field data-testid={'recommendation-type'} label="Recommendation type">
              <RadioButtonGroup
                options={recommendationTypes}
                value={recommendedActionFilter}
                onChange={(value) => {
                  setRecommendedActionFilter(value);
                }}
              />
            </Field>
          )}
        </>
      )}
    </>
  );
};
